import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { createIcon, getAllIconCategories } from "../../adapters/iconAdapter";

const AddIcon = () => {
  const history = useHistory();
  let categorySlug = history?.location?.search?.split("=")?.[1];
  const [creating, setCreating] = useState(false);
  const [categories, setCategories] = useState([]);
  const [previewSvg, setPreviewSvg] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [status, setStatus] = useState("free");

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const loadCategory = async () => {
    const result = await getAllIconCategories();
    if (!result?.isError) {
      setCategories(result?.categories);
    }
  };

  const onSubmit = async (data) => {
    if (categorySlug) {
      setCreating(true);
      const res = await createIcon({
        ...data,
        status: status,
        category: categorySlug,
      });
      setCreating(false);
      if (!res?.isError) {
        setCreating(false);
        setPreviewSvg("");
        reset();
        alert(res?.message || "Icon created successfully");
      } else {
        alert(res?.message || "Something went wrong");
      }
    } else {
      alert("Please select a folder");
    }
  };

  useEffect(() => {
    loadCategory();
    setSelectedCategory(categorySlug);
  }, [categorySlug]);

  return (
    <section className="bg-gray">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container items-center ">
          <div className="relative d-flex max-w-full mr-10 ms-5">
            <div className="w-50 bg-white p-3">
              <h2 className="font-heading text-2xl mb-8">Add Icon</h2>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="category">Category Name: </label>
                <select
                  name="category"
                  id="category"
                  className="border p-2 ms-3 rounded"
                  onChange={(e) => {
                    if (e.target.value) {
                      history.push(
                        `/icons/add-icon?categorySlug=${e.target.value}`
                      );
                      setSelectedCategory(e.target.value);
                    }
                  }}
                  value={selectedCategory}
                  defaultValue={selectedCategory}
                >
                  <option value="">Select Category</option>
                  {categories?.length > 0 &&
                    categories?.map((category) => (
                      <option key={category?.slug} value={category?.slug}>
                        {category?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="status">Select Status: </label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  id="status"
                  value={status}
                  className="border p-2 ms-3 rounded"
                >
                  <option value="paid">Paid</option>
                  <option value="free">Free</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="icon_name">Icon Name</label>
                <input
                  required
                  type="text"
                  id="icon_name"
                  {...register("name")}
                  placeholder="Example: facebook"
                  className="form-control"
                />
              </div>

              <div className="">
                <label>Past Icon SVG Here</label>
                <textarea
                  rows={7}
                  required
                  {...register("svg")}
                  onInput={(e) => setPreviewSvg(e.target.value)}
                  className="form-control"
                />
              </div>

              <button
                type="submit"
                disabled={creating ? true : false}
                style={{ cursor: creating ? "not-allowed" : "pointer" }}
                className="btn btn-primary mt-4 w-50"
              >
                {creating ? "Saving Icon..." : "Save Icon"}
              </button>
            </div>
            <div className="mt-5 ms-5 w-50 p-5 border-stroke border rounded">
              {previewSvg && (
                <div className="flex items-center">
                  <h2 className="font-heading flex items-center text-2xl">
                    Preview Icon:{" "}
                  </h2>
                  <div
                    className="ms-3 previewSvg"
                    style={{ height: "64px", width: "64px" }}
                    dangerouslySetInnerHTML={{
                      __html: previewSvg,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default AddIcon;
