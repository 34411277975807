import {setToken,postRequest,getRequest} from './apiService';
import {login,allUsers,userInfo,updateUserInfo} from '../values'

export const loginUser = async (email, password) =>{
    try{
        const responseData = await postRequest(login,{email : email , password : password});
        if(responseData.status === 200){
            /** LOGGED IN SUCCESSFULLY */
            setToken(responseData.data.data.token);

            /** SAVE USER LOGIN STATUS & TOKEN IN LOCAL STORAGE */
            localStorage.setItem('isUserLoggedIn', true);
            localStorage.setItem('userToken', responseData.data.data.token)

            return true;
        }
       // console.log('inside login adapter , response data = ', responseData)    
    }
    catch(error){
        return false;
    }
}

export const logoutUser = async () =>{
    try{
        localStorage.removeItem('isUserLoggedIn', false);
        localStorage.removeItem('userToken');
        return false;
    }
    catch(error){
        return null;
    }
    
}

export const getAllUsers = async () =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(allUsers);

        //console.log('inside login adapter , response data = ', responseData)   

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return [];
        }

         
    }
    catch(error){
        return [];
    }
}

export const getUserInfo = async (userId) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(userInfo+'?userid='+userId);

        //console.log('inside get user info , response data = ', responseData) 

        if(responseData.status === 200){
            return responseData.data.data;
        }
    }
    catch(error){
        return {}
    }
}

export const saveUserInfo = async (user) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(updateUserInfo, user );

        //console.log('inside login adapter, dashboard change password , response data = ', responseData);
        
        if(responseData.status === 200){
            /** PASSWORD CHANGE IS SUCCESSFULL */
            return {success : true, message : responseData.data.message};
        }
        else{
            return {error : responseData.data.error ? responseData.data.error : "Error Occured" , success : false } ;
        }
    }
    catch(error){
        return {error : error.toString() , success : false } ;
    }
}