import { Icon, addIcon, iconExists } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  deleteIcon,
  getIconCategoryInfoBySlug,
  updateTheIconCategory,
} from "../../../adapters/iconAdapter";

const ShowIcon = ({ prefix, icon }) => {
  if (prefix) {
    if (!iconExists(`${prefix}:${icon?.name}`)) {
      addIcon(`${prefix}:${icon?.name}`, {
        body: icon?.body,
        width: icon?.width || icon?.height || 2000,
        height: icon?.height || icon?.width || 2000,
      });
    }
    return (
      <span className="text-black">
        <Icon icon={`${prefix}:${icon?.name}`} width={32} height={32} />
      </span>
    );
  }
  return <></>;
};

const Detail = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const slug = pathname?.split("/")?.pop();
  const [category, setCategory] = useState({});
  const [updating, setUpdating] = useState(false);
  const [search, setSearch] = useState("");
  const [isIconRemoving, setIsIconRemoving] = useState(false);
  const [isColorEditable, setIsColorEditable] = useState("yes");

  const loadCategory = async (slugName) => {
    if (slugName) {
      const data = await getIconCategoryInfoBySlug(slugName);
      if (!data?.isError && data?.category) {
        setCategory(data?.category);
        setIsColorEditable(data?.category?.isColorEditable);
        // reset form data after loading category
        reset();
      } else {
        alert(data?.message || "Category not found");
      }
    }
  };

  const handleRemoveIcon = async (iconName) => {
    if (iconName) {
      setIsIconRemoving(true);
      const result = await deleteIcon(slug, iconName);
      if (!result?.isError) {
        loadCategory(slug);
      }
      setIsIconRemoving(false);
    }
  };

  useEffect(() => {
    loadCategory(slug);
  }, [slug]);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setUpdating(true);
    const res = await updateTheIconCategory({
      ...data,
      isColorEditable: isColorEditable,
      // sanitize the slug
      slug: data.slug
        ?.toLowerCase()
        ?.replace(/\s+/g, "-")
        .replace(/[^a-zA-Z0-9-_]/g, "")
        .replace(/^-+|-+$/g, ""),
      oldSlug: category?.slug,
    });
    setUpdating(false);
    if (!res?.isError && res?.newSlug) {
      loadCategory(res?.newSlug);
      history.push(`/icons/category/${res?.newSlug}`);
    } else {
      alert(res?.message || "Something went wrong");
    }
  };

  const handleChange = (e) => {
    setIsColorEditable(e.target.value);
  };

  return (
    <section className="bg-gray py-5 ">
      <div className="container bg-white pt-3 rounded">
        <div className="relative d-flex justify-content-between max-w-full me-3 ms-3">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className=" bg-white p-3 border border-stroke me-3 rounded">
                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="name" className="name">
                      Category Name
                    </label>
                    <input
                      type="text"
                      required
                      id="name"
                      defaultValue={category?.name}
                      className="form-control mb-3"
                      {...register("name")}
                    />
                  </div>

                  <div className="w-[350px]">
                    <label htmlFor="slug" className="name">
                      Category Slug
                    </label>
                    <input
                      type="text"
                      required
                      id="slug"
                      defaultValue={category?.slug}
                      className="form-control mb-3"
                      {...register("slug")}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="prefix" className="name">
                      Icon Prefix
                    </label>
                    <input
                      type="text"
                      required
                      id="prefix"
                      defaultValue={category?.prefix}
                      className="form-control mb-3"
                      {...register("prefix")}
                    />
                  </div>

                  <div className="w-[350px]">
                    <label htmlFor="isColorEditable">
                      is Color Editable(For colorful pack)
                    </label>
                    <select
                      className="form-control mb-3"
                      value={isColorEditable}
                      onChange={handleChange}
                      defaultValue={isColorEditable}
                      // {...register("isColorEditable")}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="licenseName" className="name">
                      License Name
                    </label>
                    <input
                      type="text"
                      required
                      id="licenseName"
                      className="form-control mb-3"
                      defaultValue={category?.license?.title}
                      {...register("licenseName")}
                    />
                  </div>

                  <div className="w-[350px]">
                    <label htmlFor="licenseUrl" className="name">
                      License&apos;s Url
                    </label>
                    <input
                      type="text"
                      required
                      id="licenseUrl"
                      defaultValue={category?.license?.url}
                      className="form-control mb-3"
                      {...register("licenseUrl")}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="authorName" className="name">
                      Author Name
                    </label>
                    <input
                      type="text"
                      required
                      id="authorName"
                      defaultValue={category?.author?.name}
                      className="form-control mb-3"
                      {...register("authorName")}
                    />
                  </div>

                  <div className="w-[350px]">
                    <label htmlFor="AuthorUrl" className="name">
                      Author&apos;s Url
                    </label>
                    <input
                      type="text"
                      required
                      id="authorUrl"
                      defaultValue={category?.author?.url}
                      className="form-control mb-3"
                      {...register("authorUrl")}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="version" className="name">
                      Version (Optional)
                    </label>
                    <input
                      type="text"
                      id="version"
                      defaultValue={category?.version}
                      className="form-control mb-3"
                      {...register("version")}
                    />
                  </div>
                  <div className="me-5 w-[350px]">
                    <label htmlFor="ogTitle" className="name">
                      OG Title
                    </label>
                    <input
                      type="text"
                      id="ogTitle"
                      defaultValue={category?.metaInfo?.ogTitle}
                      className="form-control mb-3"
                      {...register("ogTitle")}
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-5 w-[350px]">
                    <label htmlFor="ogImageUrl" className="name">
                      OG Image Url (Optional)
                    </label>
                    <input
                      type="text"
                      id="ogImageUrl"
                      defaultValue={category?.metaInfo?.ogImageUrl}
                      className="form-control mb-3"
                      {...register("ogImageUrl")}
                    />
                  </div>
                  <div className="w-[350px]">
                    <label htmlFor="ogDescription" className="name">
                      OG Description
                    </label>
                    <textarea
                      cols={30}
                      rows={3}
                      defaultValue={category?.metaInfo?.ogDescription}
                      id="ogDescription"
                      className="form-control mb-3"
                      {...register("ogDescription")}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={updating ? true : false}
                  style={{ cursor: updating ? "not-allowed" : "pointer" }}
                  className="btn btn-primary mt-3 w-100"
                >
                  {updating ? "Updating..." : "Update Category"}
                </button>
              </div>
            </form>
          </div>
          <div
            style={{ width: "600px" }}
            className="border border-stroke rounded p-3"
          >
            <div className="d-flex justify-content-between">
              <div className="w-[150px]">
                <input
                  type="search"
                  placeholder="Search Icon"
                  defaultValue={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control"
                />
              </div>
              <div>
                <Link
                  to={`/icons/add-icon?categorySlug=${category?.slug}`}
                  className="d-flex btn btn-primary mx-3"
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_150_4325)">
                        <path
                          d="M15.0008 7.45005H8.57578V1.00005C8.57578 0.700049 8.32578 0.425049 8.00078 0.425049C7.70078 0.425049 7.42578 0.675049 7.42578 1.00005V7.45005H1.00078C0.700781 7.45005 0.425781 7.70005 0.425781 8.02505C0.425781 8.32505 0.675781 8.60005 1.00078 8.60005H7.45078V15C7.45078 15.3 7.70078 15.575 8.02578 15.575C8.32578 15.575 8.60078 15.325 8.60078 15V8.57505H15.0008C15.3008 8.57505 15.5758 8.32505 15.5758 8.00005C15.5758 7.70005 15.3008 7.45005 15.0008 7.45005Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_150_4325">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  Add Icon
                </Link>
              </div>
              <div>
                <Link
                  to="/icons/add-category"
                  className="d-flex btn btn-primary"
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_150_4325)">
                        <path
                          d="M15.0008 7.45005H8.57578V1.00005C8.57578 0.700049 8.32578 0.425049 8.00078 0.425049C7.70078 0.425049 7.42578 0.675049 7.42578 1.00005V7.45005H1.00078C0.700781 7.45005 0.425781 7.70005 0.425781 8.02505C0.425781 8.32505 0.675781 8.60005 1.00078 8.60005H7.45078V15C7.45078 15.3 7.70078 15.575 8.02578 15.575C8.32578 15.575 8.60078 15.325 8.60078 15V8.57505H15.0008C15.3008 8.57505 15.5758 8.32505 15.5758 8.00005C15.5758 7.70005 15.3008 7.45005 15.0008 7.45005Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_150_4325">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>{" "}
                  Add Category
                </Link>
              </div>
            </div>
            <div className="text-left mt-5">
              <h3
                style={{ fontFamily: "inter" }}
                className="font-heading text-[22px] font-semibold leading-none text-black"
              >
                Total Icon: {category?.totalIcon}
              </h3>
              <p className="mt-5">
                Category Name: <strong>{category?.name}</strong>
              </p>
              <p className="mt-3">
                Category Slug: <strong>{category?.slug}</strong>
              </p>
              <p className="mt-3">
                Author: <strong>{category?.author?.name}</strong>
              </p>
              <p className="mt-3">
                Author Url: <strong>{category?.author?.url}</strong>
              </p>
              <p className="mt-3">
                License Name: <strong>{category?.license?.title}</strong>
              </p>
              <p className="mt-3">
                License Url: <strong>{category?.license?.url}</strong>
              </p>
              <p className="mt-3">
                Version: <strong>{category?.version}</strong>
              </p>
              <p className="mt-3">
                isColorEditable: <strong>{category?.isColorEditable}</strong>
              </p>
              <p className="mt-3">
                OG Title: <strong>{category?.metaInfo?.ogTitle}</strong>
              </p>
              <p className="mt-3">
                OG Description:{" "}
                <strong>{category?.metaInfo?.ogDescription}</strong>
              </p>
              <p className="mt-3">
                OG ogImageUrl: <strong>{category?.metaInfo?.ogImageUrl}</strong>
              </p>
            </div>
          </div>
        </div>
        <table className="table w-100 mt-5">
          <thead>
            <tr className="rounded-t-[3px] border-b border-stroke ">
              <th className=" py-3  text-left  text-base font-medium text-black">
                NAME
              </th>
              <th className="py-3  text-left  text-base font-medium text-black">
                ICON
              </th>
              {/* <th className="py-3  text-left  text-base font-medium text-black">
                FOLDER
              </th> */}
              <th className="py-3  text-left  text-base font-medium text-black">
                STATUS
              </th>
              <th className="py-3  text-center  text-base font-medium text-black">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {category?.icons &&
              category?.icons
                ?.filter((value) =>
                  value?.name?.toLowerCase()?.includes(search?.toLowerCase())
                )
                .map((icon) => (
                  <tr
                    key={icon?.name}
                    className="border-b border-stroke last-of-type:border-b-0"
                  >
                    <td className="py-4 text-base text-black">{icon?.name}</td>
                    <td className="py-4 text-base text-black">
                      {<ShowIcon prefix={category?.prefix} icon={icon} />}
                    </td>
                    {/* <td className="py-4 text-base text-black">
                      {category?.name}
                    </td> */}
                    <td className="py-4 text-base text-black">
                      {icon?.status}
                    </td>
                    <td className="py-4 text-base text-black ">
                      <span className="d-flex justify-content-center">
                        <Link
                          to={`/icons/update-icon/${encodeURIComponent(
                            category?.slug
                          )}?iconName=${encodeURIComponent(icon?.name)}`}
                          className="btn d-flex align-items-center btn-primary me-3"
                        >
                          <span className="me-1">
                            <svg
                              fill="#ffffff"
                              width="16"
                              height="16"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 64 64"
                            >
                              <path d="M61.2,13c-3.2-3.4-6.6-6.8-10-10.1c-0.7-0.7-1.5-1.1-2.4-1.1c-0.9,0-1.8,0.3-2.4,1L8.7,40.2c-0.6,0.6-1,1.3-1.3,2L1.9,59 c-0.3,0.8-0.1,1.6,0.3,2.2c0.5,0.6,1.2,1,2.1,1h0.4l17.1-5.7c0.8-0.3,1.5-0.7,2-1.3l37.5-37.4c0.6-0.6,1-1.5,1-2.4 S61.9,13.7,61.2,13z M20.6,52.1c-0.1,0.1-0.2,0.1-0.3,0.2L7.4,56.6l4.3-12.9c0-0.1,0.1-0.2,0.2-0.3L39.4,16l8.7,8.7L20.6,52.1z M51.2,21.5l-8.7-8.7l6.1-6.1c2.9,2.8,5.8,5.8,8.6,8.7L51.2,21.5z" />
                            </svg>
                          </span>
                          Edit
                        </Link>
                        <button
                          onClick={() => handleRemoveIcon(icon?.name)}
                          disabled={isIconRemoving ? true : false}
                          style={{
                            cursor: isIconRemoving ? "not-allowed" : "pointer",
                          }}
                          className="btn btn-danger d-flex align-items-center"
                        >
                          <span className="me-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.2258 2.20005H10.3008V1.77505C10.3008 1.02505 9.70078 0.425049 8.95078 0.425049H7.02578C6.27578 0.425049 5.67578 1.02505 5.67578 1.77505V2.20005H3.75078C3.02578 2.20005 2.42578 2.80005 2.42578 3.52505V4.27505C2.42578 4.82505 2.75078 5.27505 3.22578 5.47505L3.62578 14.125C3.67578 14.95 4.32578 15.575 5.15078 15.575H10.7758C11.6008 15.575 12.2758 14.925 12.3008 14.125L12.7508 5.45005C13.2258 5.25005 13.5508 4.77505 13.5508 4.25005V3.50005C13.5508 2.80005 12.9508 2.20005 12.2258 2.20005ZM6.82578 1.77505C6.82578 1.65005 6.92578 1.55005 7.05078 1.55005H8.97578C9.10078 1.55005 9.20078 1.65005 9.20078 1.77505V2.20005H6.85078V1.77505H6.82578ZM3.57578 3.52505C3.57578 3.42505 3.65078 3.32505 3.77578 3.32505H12.2258C12.3258 3.32505 12.4258 3.40005 12.4258 3.52505V4.27505C12.4258 4.37505 12.3508 4.47505 12.2258 4.47505H3.77578C3.67578 4.47505 3.57578 4.40005 3.57578 4.27505V3.52505ZM10.8008 14.45H5.20078C4.97578 14.45 4.80078 14.275 4.80078 14.075L4.40078 5.60005H11.6258L11.2258 14.075C11.2008 14.275 11.0258 14.45 10.8008 14.45Z"
                                fill="currentColor"
                              />
                              <path
                                d="M8.00078 8.1001C7.70078 8.1001 7.42578 8.3501 7.42578 8.6751V11.8501C7.42578 12.1501 7.67578 12.4251 8.00078 12.4251C8.30078 12.4251 8.57578 12.1751 8.57578 11.8501V8.6751C8.57578 8.3501 8.30078 8.1001 8.00078 8.1001Z"
                                fill="currentColor"
                              />
                              <path
                                d="M9.99994 8.60004C9.67494 8.57504 9.42494 8.80004 9.39994 9.12504L9.24994 11.325C9.22494 11.625 9.44994 11.9 9.77494 11.925C9.79994 11.925 9.79994 11.925 9.82494 11.925C10.1249 11.925 10.3749 11.7 10.3749 11.4L10.5249 9.20004C10.5249 8.87504 10.2999 8.62504 9.99994 8.60004Z"
                                fill="currentColor"
                              />
                              <path
                                d="M5.97594 8.60004C5.67595 8.62504 5.42595 8.90004 5.45095 9.20004L5.62595 11.4C5.65095 11.7 5.90095 11.925 6.17595 11.925C6.20095 11.925 6.20094 11.925 6.22594 11.925C6.52594 11.9 6.77594 11.625 6.75094 11.325L6.57594 9.12504C6.57594 8.80004 6.30094 8.57504 5.97594 8.60004Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                          {isIconRemoving ? "Removing..." : "Remove Icon"}
                        </button>
                      </span>
                    </td>
                  </tr>
                ))}
            {category?.icons?.length === 0 && (
              <tr>
                <td
                  className="p-5 text-base text-black align-items-center"
                  colSpan={4}
                >
                  There are no icons to display
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Detail;
