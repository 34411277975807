import { Icon, addIcon, iconExists } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  getIconCategoryInfoBySlug,
  updateIconInfo,
} from "../../adapters/iconAdapter";

const cleanUpSvg = ({ body }) => {
  let newSvg;
  const emptyDiv = window.document.createElement("div");
  emptyDiv.innerHTML = body;
  newSvg = emptyDiv.querySelector("svg");
  return newSvg?.outerHTML?.toString();
};

const UpdateIcon = () => {
  const history = useHistory();
  let categorySlug = history?.location?.pathname?.split("/")?.pop();
  let iconName = history?.location?.search?.split("=")?.pop();
  const [creating, setCreating] = useState(false);
  const [category, setCategory] = useState({});
  const [previewSvg, setPreviewSvg] = useState("");
  const [status, setStatus] = useState("free");
  const [icon, setIcon] = useState({});

  const {
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const loadCategory = async () => {
    const data = await getIconCategoryInfoBySlug(categorySlug);
    if (!data?.isError && data?.category) {
      setCategory(data?.category);
      const iconInfo = data?.category?.icons?.find(
        (icon) => icon?.name === iconName
      );
      setIcon(iconInfo);
      setStatus(iconInfo?.status);
    }
  };

  const onSubmit = async () => {
    if (categorySlug) {
      setCreating(true);
      const res = await updateIconInfo({
        svg: previewSvg ? cleanUpSvg({ body: previewSvg }) : icon?.body,
        name: iconName,
        status: status,
        oldStatus: icon?.status,
        category: categorySlug,
      });
      setCreating(false);
      if (!res?.isError) {
        setCreating(false);
        window.location.reload();
      } else {
        alert(res?.message || "Something went wrong");
      }
    } else {
      alert("Please select a folder");
    }
  };

  cleanUpSvg({ body: previewSvg });

  useEffect(() => {
    loadCategory();
  }, [categorySlug]);

  return (
    <section className="bg-gray">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container items-center ">
          <div className="relative d-flex max-w-full mr-10 ms-5">
            <div className="w-50 bg-white p-3">
              <h2 className="font-heading text-2xl mb-8">Update Icon</h2>
              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="category">
                  Category Name: {category?.name}
                </label>
              </div>

              <div className="mb-3">
                <label className="icon_name">
                  Icon Name: <b>{iconName}</b>
                </label>
              </div>

              <div className="mb-3 d-flex align-items-center">
                <label htmlFor="status">Select Status: </label>
                <select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  id="status"
                  value={status}
                  className="border p-2 ms-3 rounded"
                >
                  <option value="paid">Paid</option>
                  <option value="free">Free</option>
                </select>
              </div>
              <div className="">
                <label>Past Icon SVG Here</label>
                <textarea
                  rows={7}
                  required
                  onInput={(e) => setPreviewSvg(e.target.value)}
                  defaultValue={icon?.body}
                  className="form-control"
                />
              </div>

              <button
                type="submit"
                disabled={creating ? true : false}
                style={{ cursor: creating ? "not-allowed" : "pointer" }}
                className="btn btn-primary mt-4 w-50"
              >
                {creating ? "Saving Icon..." : "Save Icon"}
              </button>
            </div>
            <div className="mt-5 ms-5 w-50 p-5 border-stroke border rounded">
              <h2
                id="a"
                className="font-heading flex items-center text-2xl mb-8"
              >
                Current Icon:{" "}
                {<ShowIcon category={category} iconName={iconName} />}
              </h2>
              {previewSvg && (
                <div className="flex items-center">
                  <h2 className="font-heading flex items-center text-2xl">
                    Preview Icon:{" "}
                  </h2>
                  <div
                    id="b"
                    className="ms-3 previewSvg"
                    style={{ height: "64px", width: "64px" }}
                    dangerouslySetInnerHTML={{
                      __html: previewSvg,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default UpdateIcon;

const ShowIcon = ({ category, iconName }) => {
  const { prefix, icons } = category;
  const icon = icons?.find((i) => i?.name === iconName);
  if (prefix) {
    if (!iconExists(`${prefix}:${icon?.name}`)) {
      addIcon(`${prefix}:${icon?.name}`, {
        body: icon?.body,
        width: icon?.width || icon?.height || 2000,
        height: icon?.height || icon?.width || 2000,
      });
    }
    return (
      <>
        <Icon icon={`${prefix}:${icon?.name}`} width={64} height={64} />
      </>
    );
  }
  return <></>;
};
