import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllProduct, removeProduct } from "../../adapters/productAdapter";
import { getAllTags, getProductsByTag } from "../../adapters/tagAdapter";
import "../AddSnippet/AddSnippet.scss";
import ConfirmDialogue from "../ConfirmDialogue/ConfirmDialogue";
import "./ProductList.scss";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [showDialogue, setShowDialogue] = useState(false);
  const [productToDelete, setProductToDelete] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState("");

  useEffect(() => {
    getProductData();
    getTagList();
  }, []);

  /** ASYNCHRONUS METHOD TO FETCH AVAILABLE PRODUCT DATA FROM SERVER */
  const getProductData = async () => {
    try {
      const fetchedProducts = await getAllProduct();
      setProducts(fetchedProducts);
    } catch (error) {
      // console.log('error happened , error = ', error);
    }
  };

  const getTagList = async () => {
    try {
      const allTags = await getAllTags();
      // console.log('tags =', allTags);
      setTagList(allTags);
    } catch (error) {
      //  console.log('error happened, error = ', error)
    }
  };

  const getTaggedProducts = async (tagId) => {
    setSelectedTag(tagId);
    if (tagId) {
      const productList = await getProductsByTag(tagId);
      if (productList && productList.length > 0) {
        setProducts(productList);
      }
    } else {
      getProductData();
    }
  };

  const deleteProduct = async () => {
    //onClick={(e)=>deleteAction(product._id)
    try {
      const deletedProduct = await removeProduct(productToDelete);
      if (deletedProduct) {
        getProductData();
      }
    } catch (error) {
      // console.log('error happened , error = ', error);
    }
  };

  const showConfirmDialogue = (id) => {
    setShowDialogue(true);
    setProductToDelete(id);
  };

  return (
    <>
      <ConfirmDialogue
        show={showDialogue}
        closeAction={() => {
          setShowDialogue(false);
          setProductToDelete("");
        }}
        deleteAction={() => {
          deleteProduct();
          setShowDialogue(false);
        }}
      />
      <div className="container">
        <div className="accessList accessList--wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            {/* className="title" */}
            <h2>List of products</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ margin: "5px" }}>
                <select
                  value={selectedTag}
                  onChange={(e) => getTaggedProducts(e.currentTarget.value)}
                >
                  {/* <select> */}
                  <option value="">All Tags</option>
                  {tagList
                    ?.sort((a, b) => {
                      const titleA = a?.title?.toLowerCase(),
                        titleB = b?.title?.toLowerCase();
                      if (titleA < titleB) {
                        //sort string ascending
                        return -1;
                      }
                      if (titleA > titleB) {
                        return 1;
                      }
                      return 0; //default return value (no sorting)
                    })
                    ?.map((option) => (
                      <option value={option._id} key={option._id}>
                        {option.title}
                      </option>
                    ))}
                </select>
              </div>

              <Link to="/addProduct" className="main-btn">
                Create New
              </Link>
            </div>
          </div>

          <div className="accessList__table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <span className="w-140"> Product Name </span>
                  </th>
                  <th>
                    <span className="w-140"> Order </span>
                  </th>
                  <th>
                    <span className="w-140">Date</span>
                  </th>
                  <th>
                    <span className="w-160"> Paid </span>
                  </th>
                  <th>
                    <span>Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {products
                  ?.sort((a, b) => {
                    const titleA = a?.title?.toLowerCase(),
                      titleB = b?.title?.toLowerCase();
                    if (titleA < titleB) {
                      //sort string ascending
                      return -1;
                    }
                    if (titleA > titleB) {
                      return 1;
                    }
                    return 0; //default return value (no sorting)
                  })
                  ?.map((product) => (
                    <ProductListItem
                      product={product}
                      deleteAction={showConfirmDialogue}
                      key={product._id}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;

const ProductListItem = ({ product, deleteAction }) => {
  return (
    <tr>
      <td>
        <p>{product.title}</p>
      </td>
      <td>
        <p>{product.order}</p>
      </td>
      <td>
        <p>{product.createdAt.split("T")[0]}</p>
      </td>
      <td>
        <p>{product.isPaid.toString()}</p>
      </td>
      <td>
        <p>
          <Link
            to={{
              pathname: "/addProduct",
              state: { productId: product._id ? product._id : "" },
            }}
            className="main-btn"
          >
            View
          </Link>

          <button
            className="main-btn"
            style={{ backgroundColor: "red", marginLeft: "4%" }}
            onClick={() => {
              deleteAction(product._id);
            }}
          >
            Delete
          </button>
        </p>
      </td>
    </tr>
  );
};
