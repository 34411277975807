import { useState } from "react";
import { useForm } from "react-hook-form";
import { createIconCategory } from "../../../adapters/iconAdapter";

const AddIconCategory = () => {
  const [creating, setCreating] = useState(false);
  const [paidFile, setPaidFile] = useState(null);
  const [freeFile, setFreeFile] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setCreating(true);
    try {
      const res = await createIconCategory({
        ...data,
        // clear the slug
        slug: data.slug
          ?.toLowerCase()
          ?.replace(/\s+/g, "-")
          .replace(/[^a-zA-Z0-9-_]/g, "")
          .replace(/^-+|-+$/g, ""),
        paidIconZipFile: paidFile,
        freeIconZipFile: freeFile,
      });
      if (!res?.isError) {
        reset();
        alert(res?.message || "Category created successfully");
      } else {
        alert(res?.message || "Something went wrong");
      }
    } catch (error) {
      setCreating(false);
      alert(error?.message || "Something went wrong");
    } finally {
      setCreating(false);
    }
  };

  return (
    <div>
      <section className="bg-gray pb-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container pt-3 mt-5 items-center ">
            <div className="relative max-w-full me-5 ms-5">
              <div className=" bg-white p-4 rounded">
                <h2 className="font-heading text-2xl mb-12">Add Category</h2>
                <div className="d-flex ">
                  <div className="me-5 w-50">
                    <label htmlFor="name" className="name">
                      Category Name
                    </label>
                    <input
                      type="text"
                      required
                      id="name"
                      className="form-control"
                      {...register("name")}
                    />
                  </div>

                  <div className="w-50">
                    <label htmlFor="slug" className="name">
                      Category Slug
                    </label>
                    <input
                      type="text"
                      required
                      id="slug"
                      className="form-control"
                      {...register("slug")}
                    />
                  </div>
                </div>

                <div className="d-flex mt-3 align-items-center">
                  <div className="me-5 w-50">
                    <label htmlFor="prefix" className="name">
                      Icon Prefix
                    </label>
                    <input
                      type="text"
                      required
                      id="prefix"
                      className="form-control"
                      {...register("prefix")}
                    />
                  </div>

                  <div className="w-50">
                    <label htmlFor="isColorEditable">
                      is Color Editable(For colorful pack)
                    </label>
                    <select
                      className="form-control"
                      id="isColorEditable"
                      {...register("isColorEditable")}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                </div>

                <div className="d-flex mt-4">
                  <div className="me-5 w-50">
                    <label htmlFor="licenseName" className="name">
                      License Name
                    </label>
                    <input
                      type="text"
                      required
                      id="licenseName"
                      className="form-control"
                      {...register("licenseName")}
                    />
                  </div>

                  <div className="w-50">
                    <label htmlFor="licenseUrl" className="name">
                      License&apos;s Url
                    </label>
                    <input
                      type="text"
                      required
                      id="licenseUrl"
                      className="form-control"
                      {...register("licenseUrl")}
                    />
                  </div>
                </div>

                <div className="mt-4 d-flex">
                  <div className="me-5 w-50">
                    <label htmlFor="authorName" className="name">
                      Author Name
                    </label>
                    <input
                      type="text"
                      required
                      id="authorName"
                      className="form-control"
                      {...register("authorName")}
                    />
                  </div>

                  <div className="w-50">
                    <label htmlFor="AuthorUrl" className="name">
                      Author&apos;s Url
                    </label>
                    <input
                      type="text"
                      required
                      id="authorUrl"
                      className="form-control"
                      {...register("authorUrl")}
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex">
                  <div className="me-5 w-50">
                    <label htmlFor="version" className="name">
                      Version (Optional)
                    </label>
                    <input
                      type="text"
                      id="version"
                      className="form-control"
                      {...register("version")}
                    />
                  </div>
                  <div className="w-50">
                    <label htmlFor="paidFile">Paid icons zip file</label>
                    <input
                      type="file"
                      id="paidFile"
                      multiple={false}
                      accept=".zip"
                      className="form-control"
                      onChange={(e) => setPaidFile(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex">
                  <div className="me-5 w-50">
                    <label htmlFor="ogTitle" className="name">
                      OG Title (Optional)
                    </label>
                    <input
                      type="text"
                      id="ogTitle"
                      className="form-control"
                      {...register("ogTitle")}
                    />
                  </div>
                  <div className="w-50">
                    <label htmlFor="freeFile">Free icons zip file</label>
                    <input
                      type="file"
                      id="freeFile"
                      multiple={false}
                      accept=".zip"
                      className="form-control"
                      onChange={(e) => setFreeFile(e.target.files[0])}
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex">
                  <div className="me-5 w-50">
                    <label htmlFor="ogImageUrl" className="name">
                      OG Image Url (Optional)
                    </label>
                    <input
                      type="text"
                      id="ogImageUrl"
                      className="form-control"
                      {...register("ogImageUrl")}
                    />
                  </div>
                </div>

                <div className="mt-5 d-flex">
                  <div className="w-100">
                    <label htmlFor="ogDescription" className="name">
                      OG Description (Optional)
                    </label>
                    <textarea
                      cols={30}
                      rows={5}
                      id="ogDescription"
                      className="form-control"
                      {...register("ogDescription")}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={creating ? true : false}
                  style={{
                    cursor: creating ? "not-allowed" : "pointer",
                    outline: "none",
                    border: "none",
                  }}
                  className="bg-primary py-2 rounded text-light mt-5 w-50"
                >
                  {creating ? "Creating..." : "Create Category"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AddIconCategory;
