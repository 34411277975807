import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  deleteTemplate,
  getSecuredTemplateById,
} from "../../adapters/templates";
import { baseURL } from "../../config";
import styles from "./productDetail.module.scss";

const TemplateDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [product, setProduct] = useState({});

  const loadProduct = async (productId) => {
    const response = await getSecuredTemplateById(productId);
    if (response?.status === 200) {
      setProduct(response?.data?.data);
    }
  };

  useEffect(() => {
    loadProduct(id);
  }, [id]);

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are sure delete this template?");
    if (confirm) {
      const deleted = await deleteTemplate(id);
      if (!deleted?.isError) {
        history.push("/templates");
      }
    }
  };

  return (
    <div className={`${styles.productDetail} container`}>
      <div className={styles?.wrapper}>
        <div>
          <div className="card mb-3 mt-3">
            {product?.featured_image && (
              <img
                className={styles?.coverImg}
                src={`${baseURL}${product?.featured_image}`}
                alt=""
                loading="lazy"
              />
            )}
            <div className="d-flex justify-content-around p-4">
              <Link
                className="ms-5"
                title="detail"
                to={`/updateTemplates/${product?._id}`}
              >
                <button className="btn btn-warning ps-4 pe-4">Edit</button>
              </Link>
              <button
                onClick={() => handleDelete(product?._id)}
                className="btn btn-danger ms-5"
              >
                Delete
              </button>
            </div>
            <div className="card-body pt-0 p-4">
              <h2 className="mb-3 card-title">{product?.name}</h2>
              <div className=" mb-5">
                <p>Slug: {product?.slug}</p>
                <p>
                  Tags:{" "}
                  {product?.tags?.map((tag) => (
                    <span
                      className="p-1 me-2 rounded text-light bg-info"
                      key={tag?._id}
                    >
                      {tag?.name}
                    </span>
                  ))}
                </p>
                <p>Built with: {product?.built_with}</p>
                <p>Version: {product?.version}</p>
                <p>
                  Preview url:{" "}
                  <a
                    href={product?.preview_url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {product?.preview_url}
                  </a>
                </p>
                <p>Free download url: {product?.free_download_url}</p>
                <p>
                  Guest user can access from CDN:{" "}
                  {product?.free_access_from_cdn === true ? "Yes" : "No"}
                </p>

                <p>
                  Created:{" "}
                  {new Date(product?.createdAt).toLocaleDateString("en-GB")}
                </p>
                <p>
                  Last Update:{" "}
                  {new Date(product?.updatedAt).toLocaleDateString("en-GB")}
                </p>
                {product?.downloadUrls?.map((item) => (
                  <div className="mt-3" key={item?._id}>
                    <p>Bunny CDN url: {item?.bunnyCDNurl}</p>
                    <p>display Name: {item?.displayName}</p>
                    <p>Access Token: {item?.accessToken}</p>
                  </div>
                ))}
              </div>
              <h4>Product Detail</h4>
              <div
                className="w-100 detail_body"
                dangerouslySetInnerHTML={{ __html: product?.detail }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateDetail;
