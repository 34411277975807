import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAllPlans } from "../../adapters/planAdapter";
import { getProductDetail, saveProduct } from "../../adapters/productAdapter";
import { getAllTags } from "../../adapters/tagAdapter";
import { baseURL } from "../../config";
import "./AddSnippet.scss";
import FrameworkInfo from "../Cmmon/FrameworkInfo";

const tabInfo = [
  {
    id: 1,
    title: "React",
  },
  {
    id: 2,
    title: "Vue",
  },
];

const AddSnippet = () => {
  /** LOCATION , HISTORY HOOK FROM REACT ROUTER TO CATCH THE PROPS PASSED TO THIS COMPONENT */
  const location = useLocation();
  const history = useHistory();

  const [productInfo, setProductInfo] = useState({});
  const [price, setPrice] = useState("paid");
  const [htmlCode, setHtmlCode] = useState("");
  const [reactCode, setReactCode] = useState("");
  const [vueCode, setVueCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [title, setTitle] = useState("");
  const [productMessage, setProductMessage] = useState("");
  const [description, setDescription] = useState("");
  const [productOrder, setProductOrder] = useState("");
  const [base64Image, setBase64Image] = useState(null);
  const [tagSelectOption, setTagSelectOption] = useState([]);
  const [productTag, setProductTag] = useState("");
  const [planList, setPlanList] = useState([]);

  const [reactInfo, setReactInfo] = useState({
    framework: "react",
    title: "",
    description: "",
  });
  const [vueInfo, setVueInfo] = useState({
    framework: "vue",
    title: "",
    description: "",
  });

  const [activeTab, setActiveTab] = useState(1);

  /** USING EFFECT HOOK TO LOAD ABOUT PRODUCT DETAILS IF USER COMES FROM PRODUCT LIST PAGE  */
  useEffect(() => {
    if (location.state) {
      // console.log('has location state , ', location.state.productId)
      getProductInfo(location.state.productId);
    } else {
      // console.log('no product id');
      fetchPlans();
    }

    /** WHETHER ITS A NEW PRODUCT OR NOT FETCH ALL TAGS */
    getTagInfo();
  }, []);

  useEffect(() => {
    /** CREATE CATEGORY CHECKBOX ARRAY AFTER FETCHING PLAN DETAILS DATA */
    fetchPlans();
  }, [productInfo]);

  /** NEED TO DETECT ONLY IMAGE AND DISCARD OTHER TYPES OF FILE */
  const detectImageChange = async (event) => {
    // console.log('inside image on change method ');
    event.preventDefault();

    if (event.currentTarget.files.length > 0) {
      const base64File = await toBase64(event.currentTarget.files[0]);
      setBase64Image(base64File);
    }
  };

  /** NEED TO CONVERT THIS METHOD AS A SERVICE TO THIS APPLICATION */
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        /** AFTER LOADING THE FILE WITH FILE READER , SET THE SOURCE ATTRIBUTE OF IMAGE PREVIEW */
        const imagePreview = document.getElementById("previewImage");
        imagePreview.style.display = "block";
        imagePreview.setAttribute("src", event.target.result);

        /** RESOLVE THE PROMISE TO RETURN THE BASE64 CONVERTED VALUE */
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });

  /** METHOD TO FETCH PRODUCT DETAILS INFORMATION FROM API SERVER */
  const getProductInfo = async (id) => {
    try {
      const productInfo = await getProductDetail(id);

      // console.log('product Info = ', productInfo);

      if (productInfo) {
        setProductInfo(productInfo);
        setPrice(productInfo.isPaid ? "paid" : "free");
        setTitle(productInfo.title);
        setHtmlCode(productInfo.html);
        setReactCode(productInfo.reactCode);
        setVueCode(productInfo.vueCode);
        setCssCode(productInfo.css);
        setProductTag(productInfo.tag);
        if (productInfo.productMessage) {
          setProductMessage(productInfo.productMessage);
        }
        if (productInfo?.description) {
          setDescription(productInfo.description);
        }

        if (productInfo.order) {
          setProductOrder(productInfo.order);
        }

        if (productInfo.frameworkInfo) {
          setReactInfo(productInfo.frameworkInfo[0]);
          setVueInfo(productInfo.frameworkInfo[1]);
        }

        if (productInfo.previewImage && productInfo.previewImage != "") {
          const imagePreview = document.getElementById("previewImage");
          imagePreview.style.display = "block";
          imagePreview.setAttribute(
            "src",
            `${baseURL}${productInfo.previewImage}`
          );
        }
      }
    } catch (error) {
      //  console.log('error happened , error = ', error);
    }
  };

  const fetchPlans = async () => {
    try {
      const fetchedPlans = await getAllPlans();

      const selectedPlanArray = [];
      /** CREATE CHECKBOX ARRAY WITH INITIAL VALUE SET TO FALSE */
      for (let i = 0; i < fetchedPlans.length; i++) {
        selectedPlanArray.push({
          id: fetchedPlans[i]._id,
          title: fetchedPlans[i].name,
          checkedValue: true,
        });
      }

      /** IF ANY PLAN INFORMATION EXISTS UPDATE THE INITIAL CHEKBOX ARRAY ACCORDING TO SELECTED CATEGORY OF EXISTING PLAN */
      if (productInfo && productInfo.plans && productInfo.plans.length > 0) {
        if (selectedPlanArray && selectedPlanArray.length > 0) {
          for (let i = 0; i < selectedPlanArray.length; i++) {
            if (productInfo.plans.includes(selectedPlanArray[i].id)) {
              selectedPlanArray[i].checkedValue = true;
            } else {
              selectedPlanArray[i].checkedValue = false;
            }
          }
        }
      }

      setPlanList(selectedPlanArray);
    } catch (error) {
      // console.log('error happened , error = ', error);
    }
  };

  const changePlanHandler = async (id, checkedValue) => {
    for (let i = 0; i < planList.length; i++) {
      if (planList[i].id === id) {
        planList[i].checkedValue = !checkedValue;
      }
    }
    setPlanList([...planList]);
  };

  /** METHOD TO FETCH ALL TAGS FROM SERVER  */
  const getTagInfo = async () => {
    try {
      const tags = await getAllTags();

      setTagSelectOption(
        tags.map((tag) => ({ label: tag.title, value: tag._id }))
      );
    } catch (error) {
      // console.log('error happened , error = ', error);
    }
  };

  const productCreateUpdate = async (event) => {
    event.preventDefault();

    // console.log('base64 image = ', base64Image);

    /** CHECK FOR VALIDATION */
    if (title == "" || price == "" || htmlCode == "" || productTag == "") {
      alert("one or more fields remains empty");
    } else {
      /** CREATE PRODUCT OBJECT WITH ALL THE VALUES */

      let product = {
        title: title,
        isPaid: price === "paid" ? true : false,
        html: htmlCode,
        reactCode: reactCode,
        vueCode: vueCode,
        css: cssCode,
        image: base64Image,
        tag: productTag,
        productMessage: productMessage,
        description: description,
        order: productOrder,
        plans: planList
          .filter((item) => item.checkedValue)
          .map((item) => item.id),

        frameworkInfo: [reactInfo, vueInfo],
      };
      if (location.state) {
        /** CONCATENATE WITH PRODUCT ID FROM LOCATION HOOK FETCHED FROM REACT ROUTER DOM */
        product = { ...product, _id: location.state.productId };
      }

      try {
        /** SEND SAVE PRODUCT REQUEST TO API SERVER WHETHER IT'S AN UPDATE OR CREATE */
        const savedProduct = await saveProduct(product);

        /** REDIRECT USER TO PRODUCT LIST PAGE IS SAVING OF PRODUCT IS SUCCESSFULL */
        if (savedProduct && savedProduct._id) {
          history.push("/productList");
        } else {
          alert(
            `Error happened ${savedProduct.error ? savedProduct.error : ""}`
          );
        }
      } catch (error) {
        //console.log('error happened on saving product')
      }
    }
  };

  return (
    <div className="AddSnippet-wrapper">
      <div className="container">
        <form action="#0">
          <div className="block-type">
            <h3>Block Type</h3>
            <div className="check-type">
              <div className="form-input">
                <input
                  type="radio"
                  name="blockType"
                  value="paid"
                  checked={price === "paid"}
                  onChange={(e) => setPrice(e.currentTarget.value)}
                />
                <label htmlFor="blockType">Paid</label>
              </div>
              <div className="form-input">
                <input
                  type="radio"
                  name="blockType"
                  value="free"
                  checked={price === "free"}
                  onChange={(e) => setPrice(e.currentTarget.value)}
                />
                <label htmlFor="blockType">Free</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="block-type">
                <h3>Title</h3>
                <div className="form-input">
                  <input
                    type="text"
                    name="blockType"
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="block-type">
                <h3>Order</h3>
                <div className="form-input">
                  <input
                    type="number"
                    name="text"
                    placeholder="Enter order"
                    value={productOrder}
                    onChange={(e) => setProductOrder(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="block-type">
                <h3>Tag</h3>
                <div className="form-input">
                  <select
                    value={productTag}
                    onChange={(e) => setProductTag(e.currentTarget.value)}
                  >
                    <option value="">Select Tag</option>
                    {tagSelectOption
                      ?.sort((a, b) => {
                        const titleA = a?.label?.toLowerCase(),
                          titleB = b?.label?.toLowerCase();
                        if (titleA < titleB) {
                          //sort string ascending
                          return -1;
                        }
                        if (titleA > titleB) {
                          return 1;
                        }
                        return 0; //default return value (no sorting)
                      })
                      ?.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="block-code">
                <h3>Product Description</h3>
                <div className="code-input">
                  <textarea
                    name="description"
                    style={{ height: "130px" }}
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="single-input">
                <label htmlFor="email">Select Plans</label>
                {planList.map((item) => (
                  <div
                    key={item.id}
                    style={{ marginTop: "5px", marginBottom: "3px" }}
                  >
                    <input
                      type="checkbox"
                      id={item.id}
                      name={item.title}
                      style={{ width: "fit-content" }}
                      checked={item.checkedValue}
                      onChange={() => {
                        changePlanHandler(item.id, item.checkedValue);
                      }}
                    />
                    <label
                      htmlFor={item.id}
                      style={{ display: "inline-block", marginLeft: "5px" }}
                    >
                      {item.title}{" "}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="block-type">
                <h3>Notice</h3>
                <div className="form-input">
                  <input
                    type="text"
                    name="blockType"
                    value={productMessage}
                    onChange={(e) => setProductMessage(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="block-code">
            <h3>Block Code</h3>
            <div className="row">
              <div className="col-lg-6">
                <div className="code-input">
                  <label htmlFor="HTMLCode">HTML Code</label>
                  <textarea
                    name="HTMLCode"
                    cols="4"
                    value={htmlCode}
                    onChange={(e) => setHtmlCode(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="code-input">
                  <label htmlFor="CSSCode">CSS Code</label>
                  <textarea
                    name="CSSCode"
                    cols="4"
                    value={cssCode}
                    onChange={(e) => setCssCode(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="code-input">
                  <label htmlFor="reactCode">React Code</label>
                  <textarea
                    name="reactCode"
                    cols="4"
                    value={reactCode}
                    onChange={(e) => setReactCode(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="code-input">
                  <label htmlFor="vueCode">Vue Code</label>
                  <textarea
                    name="vueCode"
                    cols="4"
                    value={vueCode}
                    onChange={(e) => setVueCode(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="preview-image">
            <h3>Preview Image</h3>
            <div className="file-upload">
              <input
                type="file"
                name="previewImage"
                onChange={detectImageChange}
              />
              <label htmlFor="previewImage">
                <i className="lni lni-upload" style={{ zIndex: 2 }}></i>
                <span style={{ zIndex: 2 }}> Upload Preview Image </span>

                <img
                  id="previewImage"
                  src=""
                  alt="preview Image"
                  width="348"
                  height="198"
                  style={{ position: "inherit", display: "none" }}
                />
              </label>
            </div>
          </div>

          <div className="mb-5">
            <h4 className="mt-5"> Framework specific metadata</h4>

            <div className="flex">
              {tabInfo.map((tab) => (
                <button
                  key={tab.id}
                  onClick={(e) => {
                    e.preventDefault();

                    setActiveTab(tab.id);
                  }}
                  className={`btn ${
                    tab.id === activeTab ? "btn-active" : "btn-default"
                  }`}
                >
                  {tab.title}
                </button>
              ))}
            </div>

            {activeTab === 1 ? (
              <FrameworkInfo
                framework={"React"}
                info={reactInfo}
                setInfo={setReactInfo}
              />
            ) : (
              <FrameworkInfo
                framework={"Vue"}
                info={vueInfo}
                setInfo={setVueInfo}
              />
            )}
          </div>

          <div className="submit-button">
            <button
              name="submitButton"
              className="main-btn"
              onClick={productCreateUpdate}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSnippet;
