import React from "react";

const FrameworkInfo = ({ info, framework, setInfo }) => {
  const handleChange = (e) => {
    const detail = {
      ...info,
      [e.target.name]: e.target.value,
      framework: framework.toLowerCase(),
    };
    setInfo(detail);

    console.log("info = ", info);
  };

  return (
    <div className="mt-8  col-md-6">
      <h6 className="mt-4 mb-2">{framework} Info </h6>

      <div className="single-input">
        <p>Category Name</p>
        <input
          className="mb-4"
          type="text"
          name="title"
          value={info?.title || ""}
          onChange={handleChange}
        />

        <p>Description</p>
        <textarea
          type="text"
          name="description"
          value={info?.description || ""}
          onChange={handleChange}
          rows={5}
          cols={44}
        />
      </div>
    </div>
  );
};

export default FrameworkInfo;
