import "../AccountSettings/AccountSettings.scss";
import { useLocation, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getUserInfo, saveUserInfo } from '../../adapters/loginAdapter';

const UpdateUser = () => {

    const location = useLocation();
    const history = useHistory();

    const [userEmail, setUserEmail] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userPassword,setUserPassword] = useState("");

    /** USING EFFECT HOOK TO LOAD ABOUT TAG DETAILS IF USER COMES FROM TAG LIST PAGE  */
    useEffect(() => {
        if (location.state) {
          //  console.log('has location state , userId = ', location.state.userId)
            fetchUserInfo(location.state.userId)
        }
        else {
          //  console.log('no user id')
        }
    }, [])

    const fetchUserInfo = async (id) => {
        try {
            const userInfo = await getUserInfo(id);
            if (userInfo) {
                setUserEmail(userInfo.email);
                setUserFirstName(userInfo.firstName);
                setUserLastName(userInfo.lastName);
            }
        }
        catch (error) {
           // console.log('error happened , error = ', error);
        }
    }

    const updateUserInfo = async(event) =>{
        event.preventDefault();

        let user = {
            email: userEmail,
            firstName: userFirstName,
            lastName: userLastName,
        }
        if (location.state) {
            /** CONCATENATE WITH PRODUCT ID FROM LOCATION HOOK FETCHED FROM REACT ROUTER DOM */
            user = { ...user, id: location.state.userId }
        }
        if(userPassword && userPassword.length >0){
            user = { ...user, password: userPassword }
        }

        const response = await saveUserInfo(user);
        if (response.error) {
            alert(response.error);
        }
        else {
            history.push('/userList')
        }
    }

    return (
        <>
            <div className="container">
                <div className="accountSettings">
                    <div className="accountSettings__box">
                        <div className="subtitle">
                            <h4>Create Tag</h4>
                        </div>
                    </div>

                    <form className="accountSettings__box__info">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">First Name</label>
                                    <input type="text" name="text" placeholder="First Name of user"
                                        value={userFirstName}
                                        onChange={(e) => setUserFirstName(e.currentTarget.value)} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">Last Name</label>
                                    <input type="text" name="text" placeholder="Last Name of user"
                                        value={userLastName}
                                        onChange={(e) => setUserLastName(e.currentTarget.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" name="text" placeholder="Email of user"
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.currentTarget.value)} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="single-input">
                                    <label htmlFor="email">Password</label>
                                    <input type="password" name="text" placeholder=""
                                        value={userPassword}
                                        onChange={(e) => setUserPassword(e.currentTarget.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                                <div className="col-lg-12">
                                    <div className="button-wrapper">
                                        <button id="createTag" className="main-btn" onClick={updateUserInfo}>
                                            Update Info
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default UpdateUser;