import {
  addTemplates,
  addTemplatesTag,
  getSecuredTemplates,
  getTemplates,
  getTemplateTag,
  removeTemplate,
  removeTemplatesTag,
  updateTemplates,
  updateTemplatesTag,
} from "../../values";
import {
  deleteRequest,
  getRequest,
  postRequest,
  setToken,
} from "../apiService";

export async function addTemplate(product) {
  setToken(localStorage.getItem("userToken"));
  return await postRequest(addTemplates, product);
}

export async function getAllTemplates() {
  setToken(localStorage.getItem("userToken"));
  return await getRequest(getTemplates);
}

export async function getAllSecuredTemplates() {
  setToken(localStorage.getItem("userToken"));
  return await getRequest(`${getSecuredTemplates}/allTemplates`);
}

export async function getSecuredTemplateById(id) {
  setToken(localStorage.getItem("userToken"));
  return await getRequest(`${getSecuredTemplates}/${id}`);
}

export async function updateTemplate(product) {
  setToken(localStorage.getItem("userToken"));
  return await postRequest(updateTemplates, product);
}

export async function deleteTemplate(id) {
  setToken(localStorage.getItem("userToken"));
  return await deleteRequest(`${removeTemplate}/${id}`);
}

export async function addTemplateTag(tag) {
  setToken(localStorage.getItem("userToken"));
  return await postRequest(addTemplatesTag, tag);
}

export async function updateTemplateTag(tag) {
  setToken(localStorage.getItem("userToken"));
  return await postRequest(updateTemplatesTag, tag);
}

export async function getAllTemplateTags() {
  setToken(localStorage.getItem("userToken"));
  return await getRequest(getTemplateTag);
}

export async function getTemplateTagById(id) {
  setToken(localStorage.getItem("userToken"));
  return await getRequest(`${getTemplateTag}${id}`);
}

export async function deleteTemplateTag(id) {
  setToken(localStorage.getItem("userToken"));
  return await deleteRequest(`${removeTemplatesTag}/${id}`);
}
