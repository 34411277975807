import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteTemplate, getAllTemplates } from "../../adapters/templates";
import { baseURL } from "../../config";

const Templates = () => {
  const [allTemplates, setAllTemplates] = useState([]);

  const loadAllTemplate = async () => {
    const templates = await getAllTemplates();
    if (templates?.status === 200) {
      setAllTemplates(templates?.data?.data);
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure to delete this Tag");
    if (confirm) {
      const deleteResponse = await deleteTemplate(id);
      if (deleteResponse?.status === 200) {
        loadAllTemplate();
      }
    }
  };

  useEffect(() => {
    loadAllTemplate();
  }, []);

  return (
    <div className="container mt-5">
      <Link to="/addTemplates" className="main-btn cursor-pointer">
        Add Template
      </Link>
      <div style={{ flexWrap: "wrap" }} className="w-100 mt-5 d-flex">
        {allTemplates?.length > 0 &&
          allTemplates?.map((template) => (
            <div
              key={template?._id}
              className="card bg-light m-3"
              style={{ minWidth: "18rem", maxWidth: "18rem" }}
            >
              <img
                style={{ height: "150px", objectFit: "cover" }}
                className="card-img-top"
                src={`${baseURL}${template?.featured_image}`}
                alt="template feature img"
              />
              <div className="card-header">{template?.name}</div>
              <div className="card-body">
                <h5 className="">Slug: {template?.slug}</h5>
              </div>
              <div className="d-flex ps-4 pe-4 pb-2">
                <button
                  onClick={() => handleDelete(template?._id)}
                  title="delete"
                  className="btn btn-danger ps-2 pe-2 cursor-pointer"
                >
                  Delete
                </button>
                <Link
                  className="ms-auto cursor-pointer"
                  to={`/singleTemplate/${template?._id}`}
                >
                  <button className="btn btn-info ms-3 me-3">Detail</button>
                </Link>
                <Link
                  className="ms-auto cursor-pointer"
                  to={`/updateTemplates/${template?._id}`}
                >
                  <button className="btn btn-warning ps-3 pe-3">Edit</button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Templates;
