import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory } from "react-router-dom";
import { addTemplate, getAllTemplateTags } from "../../adapters/templates";
import Spinner from "../Spinner";
import styles from "./AddProduct.module.scss";

const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const AddTemplate = () => {
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [value, setValue] = useState("");
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [featuredImageBase64, setFeaturedImageBase64] = useState(null);
  const [short_detail, setShortDetail] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [accessCDN, setAccessCDN] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [htmlFile, setHtmlFile] = useState({});
  const [reactFile, setReactFile] = useState({});

  const handleAdd = async (data) => {
    try {
      const newData = {
        name: data?.name,
        slug: data?.slug?.toString().trim().replace(/\s+/g, "-").toLowerCase(),
        built_with: data?.built_with,
        version: data?.version,
        preview_url: data?.preview_url,
        free_download_url: data?.free_download_url,
        free_access_from_cdn: accessCDN,
        downloadUrls: [],
        short_detail: short_detail,
        detail: value || " ",
        image: featuredImageBase64,
        tags: selectedTags?.map((i) => {
          return { _id: i?._id };
        }),
        ogTitle: data?.ogTitle,
        ogImageLink: data?.ogImageLink,
        ogDescription: data?.ogDescription,
      };
      if (htmlFile?.bunnyCDNurl) {
        htmlFile.type = "html";
        newData.downloadUrls.push(htmlFile);
      }
      if (reactFile?.bunnyCDNurl) {
        reactFile.type = "react";
        newData.downloadUrls.push(reactFile);
      }
      setIsLoading(true);
      const response = await addTemplate(newData);
      setIsLoading(false);
      setError(response);
      if (response?.status === 200) {
        history.push(`/singleTemplate/${response?.data?.data?._id}`);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  const handleTags = (e) => {
    if (e.target.value) {
      const tag = JSON.parse(e.target.value);
      if (!selectedTags?.map((item) => item?._id).includes(tag?._id)) {
        const newTag = [...selectedTags, tag];
        setSelectedTags(newTag);
        e.target.value = "";
      } else {
        alert("This tag is already added");
      }
    }
  };

  const removeTag = (id) => {
    const existingTag = selectedTags.filter((item) => item?._id !== id);
    setSelectedTags(existingTag);
  };

  const handleEditor = (content, delta, source, editor) => {
    setValue(editor.getHTML());
    setShortDetail(
      editor
        ?.getText()
        ?.slice(0, 140)
        ?.replace(/\s{2,}/g, " ")
        ?.trim()
    );
  };

  const getAllTags = async () => {
    const tags = await getAllTemplateTags();
    if (tags?.status === 200) {
      setAllTags(tags?.data?.data);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        setFeaturedImageBase64(`data:image/png;base64,${encoded}`);
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div className={`${styles.AddProduct} container`}>
      <div className={styles.wrapper}>
        <h3>Add Template</h3>
        <div className={styles.formContainer}>
          <form
            onSubmit={handleSubmit(handleAdd)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className={`${styles.basic}`}>
              <div className="mb-3 me-5 w-50">
                <label htmlFor="name" className="form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
              </div>
              <div className="mb-3 w-50">
                <label htmlFor="slug" className="form-label">
                  Slug <span className="text-danger">*</span>{" "}
                  <small>slug must be unique for every item</small>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="slug"
                  placeholder="slug"
                  {...register("slug", { required: true })}
                />
              </div>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 me-5 w-50">
                <label htmlFor="built" className="form-label">
                  Built with
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="built"
                  {...register("built_with", { required: true })}
                  placeholder="example: Tailwind"
                />
              </div>
              <div className="mb-3 w-50">
                <label htmlFor="version" className="form-label">
                  Version
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="version"
                  placeholder="example: 0.1.1"
                  {...register("version", { required: true })}
                />
              </div>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 me-5 w-50">
                <label htmlFor="preview" className="form-label">
                  Preview Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="preview"
                  placeholder="example: https://www.exemple-item.preview"
                  {...register("preview_url", { required: true })}
                />
              </div>
              <div className="mb-3 w-50">
                <label htmlFor="featuredImage" className="form-label">
                  Featured Image
                </label>
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files[0]) {
                      setFeaturedImage(e.target.files[0]);
                      getBase64(e.target.files[0]);
                    }
                  }}
                  multiple={false}
                  accept="image/*"
                  className="form-control"
                  id="example"
                />
              </div>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 w-50"></div>
              {featuredImage?.size && (
                <div className="mb-3 ms-5 w-50">
                  <img
                    width={600}
                    src={
                      featuredImage?.size && URL.createObjectURL(featuredImage)
                    }
                    alt=""
                  />
                </div>
              )}
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 w-50">
                <label htmlFor="taf" className="form-label">
                  Tags
                </label>
                <select
                  className="form-control"
                  name="tag"
                  onChange={handleTags}
                >
                  <option value="">Select</option>
                  {allTags?.length > 0 &&
                    allTags?.map((tag) => (
                      <option key={tag?._id} value={JSON.stringify(tag)}>
                        {tag?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="mb-3 mt-5 ms-4 w-50">
                {selectedTags?.length > 0 &&
                  selectedTags?.map((item) => (
                    <span
                      key={item?._id}
                      className="block rounded bg-primary text-light p-1 pe-0 me-2 mb-2"
                    >
                      {item?.name}{" "}
                      <span
                        onClick={() => removeTag(item?._id)}
                        title="delete"
                        style={{ cursor: "pointer" }}
                        className="rounded-circle ms-2 bg-danger p-1"
                      >
                        x
                      </span>
                    </span>
                  ))}
              </div>
            </div>

            {/* product zip filename */}
            <div>
              <div className={`w-100`}>
                <div className="w-50">
                  <div className="mb-4 mt-3">
                    <label htmlFor="free_download_url">
                      Free Download URL (optional)
                    </label>
                    <input
                      type="text"
                      name="free_download_url"
                      placeholder=""
                      className="form-control"
                      id="free_download_url"
                      {...register("free_download_url")}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="" className="fs-6 form-label">
                      Guest can access from cdn
                    </label>
                    <select
                      onChange={(e) => setAccessCDN(e.target.value)}
                      className="ms-2 p-2 fs-6"
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-4 w-50">
                    <h3>Html File</h3>
                    <label htmlFor="bunnyCDNurl" className="fs-5 form-label">
                      bunnyCDNurl
                    </label>
                    <input
                      type="text"
                      name="bunnyCDNurl"
                      placeholder=""
                      className="form-control"
                      id="bunnyCDNurl"
                      defaultValue={htmlFile?.bunnyCDNurl}
                      onInput={(e) => {
                        setHtmlFile({
                          ...htmlFile,
                          bunnyCDNurl: e.currentTarget.value,
                        });
                      }}
                    />
                    <label
                      htmlFor="displayName"
                      className="fs-5 mt-5 form-label"
                    >
                      display file name
                    </label>
                    <input
                      type="text"
                      name="displayName"
                      placeholder=""
                      className="form-control"
                      id="displayName"
                      defaultValue={htmlFile?.displayName}
                      onInput={(e) => {
                        setHtmlFile({
                          ...htmlFile,
                          displayName: e.currentTarget.value,
                        });
                      }}
                    />
                    <label
                      htmlFor="accessToken"
                      className="fs-5 mt-5 form-label"
                    >
                      access token
                    </label>
                    <input
                      type="text"
                      name="accessToken"
                      placeholder=""
                      className="form-control"
                      id="accessToken"
                      defaultValue={htmlFile?.accessToken}
                      onInput={(e) => {
                        setHtmlFile({
                          ...htmlFile,
                          accessToken: e.currentTarget.value,
                        });
                      }}
                    />
                  </div>
                  <div className={`ms-5 w-50`}>
                    <div className="mb-4">
                      <h3>React File</h3>
                      <label
                        htmlFor="bunnyCDNurlReact"
                        className="fs-5 form-label"
                      >
                        bunnyCDNurl
                      </label>
                      <input
                        type="text"
                        name="bunnyCDNurlReact"
                        placeholder=""
                        className="form-control"
                        id="bunnyCDNurlReact"
                        defaultValue={reactFile?.bunnyCDNurl}
                        onInput={(e) => {
                          setReactFile({
                            ...reactFile,
                            bunnyCDNurl: e.currentTarget.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="displayNameReact"
                        className="fs-5 mt-5 form-label"
                      >
                        display file name
                      </label>
                      <input
                        type="text"
                        name="displayNameReact"
                        placeholder=""
                        className="form-control"
                        id="displayNameReact"
                        defaultValue={reactFile?.displayName}
                        onInput={(e) => {
                          setReactFile({
                            ...reactFile,
                            displayName: e.currentTarget.value,
                          });
                        }}
                      />
                      <label
                        htmlFor="accessTokenReact"
                        className="fs-5 mt-5 form-label"
                      >
                        access token
                      </label>
                      <input
                        type="text"
                        name="accessTokenReact"
                        placeholder=""
                        className="form-control"
                        id="accessTokenReact"
                        defaultValue={reactFile?.accessToken}
                        onInput={(e) => {
                          setReactFile({
                            ...reactFile,
                            accessToken: e.currentTarget.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Open graph data */}
            <div className={`${styles.basic}`}>
              <div className="mb-3 me-5 w-75">
                <label htmlFor="ogTitle" className="form-label">
                  OG Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ogTitle"
                  placeholder="open graph Title"
                  {...register("ogTitle", { required: false })}
                />
              </div>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 w-75">
                <label htmlFor="ogImageLink" className="form-label">
                  OG Image URL:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ogImageLink"
                  placeholder="Open Graph Image URL"
                  {...register("ogImageLink", { required: false })}
                />
              </div>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-3 w-75">
                <label htmlFor="ogDescription" className="form-label">
                  OG Description:
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  id="ogDescription"
                  name="ogDescription"
                  placeholder="Open Graph Description"
                  rows={6}
                  {...register("ogDescription", { required: false })}
                />
              </div>
            </div>

            {/* editor */}
            <div className="mt-5 mb-5 text-center">
              <h1>
                {"<"} Here is Editor {"/>"}
              </h1>
            </div>
            <div className={`${styles.basic}`}>
              <div className="mb-4 w-75">
                <label
                  htmlFor="shortDetail"
                  className="fw-bold fs-5 form-label"
                >
                  Short Detail{" "}
                  <span>
                    {short_detail?.length > 0 ? short_detail?.length : "0"} /140
                  </span>
                </label>
                <textarea
                  style={{ resize: "none" }}
                  type="text"
                  className="form-control"
                  id="shortDetail"
                  placeholder="Short Detail"
                  rows="2"
                  maxLength={140}
                  value={short_detail}
                  // {...register("short_detail", { required: true })}
                />
              </div>
            </div>
            <h4>Full Detail</h4>
            <ReactQuill
              modules={modules}
              theme="snow"
              value={value}
              onChange={handleEditor}
            />
            <div className="text-center mt-5 mb-5">
              <p
                className={`mb-4 ${
                  error?.isError ? "text-danger" : "text-success"
                }`}
              >
                {error?.error?.sqlMessage || error?.message}
              </p>
              <p className="mb-4 text-danger">
                Please check again all information provided are correct
              </p>
              <button
                className="btn btn-primary"
                disabled={isLoading ? true : false}
                type="submit"
              >
                Add Template {isLoading && <Spinner />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
