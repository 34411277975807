import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory, useLocation } from "react-router-dom";
import { getAllCategories } from "../../adapters/categoryAdapter";
import { getTagDetail, saveTag } from "../../adapters/tagAdapter";
import { baseURL } from "../../config";
import "../AccountSettings/AccountSettings.scss";
import FrameworkInfo from "../Cmmon/FrameworkInfo";

const modules = {
  toolbar: [
    [{ font: ["inter"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};

const tabInfo = [
  {
    id: 1,
    title: "React",
  },
  {
    id: 2,
    title: "Vue",
  },
];

const AddTag = () => {
  const location = useLocation();
  const history = useHistory();

  const [tagName, setTagName] = useState("");
  const [tagTitle, setTagTitle] = useState("");
  const [label, setLabel] = useState("");
  const [tagOrder, setTagOrder] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [selectOption, setSelectOption] = useState([]);
  const [previewImgJpg, setPreviewImgJpg] = useState("");
  const [previewImgSvg, setPreviewImgSvg] = useState("");
  const [isAvailableAllAccess, setIsAvailableAllAccess] = useState(true);

  const [reactInfo, setReactInfo] = useState({
    framework: "react",
    title: "",
    description: "",
  });

  const [vueInfo, setVueInfo] = useState({
    framework: "vue",
    title: "",
    description: "",
  });

  const [activeTab, setActiveTab] = useState(1);

  /** USING EFFECT HOOK TO LOAD ABOUT TAG DETAILS IF USER COMES FROM TAG LIST PAGE  */
  useEffect(() => {
    if (location.state) {
      // console.log('has location state , ', location.state.productId)
      getTagInfo(location.state.tagId);
    } else {
      // console.log('no product id')
    }

    getCategoryInfo();
  }, []);

  const getCategoryInfo = async () => {
    try {
      const categories = await getAllCategories();

      setSelectOption(
        categories.map((item) => ({ label: item.title, value: item._id }))
      );
    } catch (error) {
      // console.log('error happened , error = ', error);
    }
  };

  const getTagInfo = async (id) => {
    try {
      const tagInfo = await getTagDetail(id);

      if (tagInfo) {
        //  console.log('inside add tag , taginfo ', tagInfo);

        setTagName(tagInfo.title);
        setParentCategory(tagInfo.category);
        setIsFeatured(tagInfo.isFeatured);
        setTagTitle(tagInfo.name);
        setTagDescription(tagInfo.description);
        setIsAvailableAllAccess(tagInfo?.isAvailableAllAccess);
        if (tagInfo.order) {
          setTagOrder(tagInfo.order);
        }

        if (tagInfo?.label) {
          setLabel(tagInfo?.label);
        }

        if (tagInfo.previewImageSVG) {
          const imagePreviewElement =
            document.getElementById("imagePreviewSvg");
          if (imagePreviewElement) {
            imagePreviewElement.style.display = "block";
            imagePreviewElement.setAttribute(
              "src",
              `${baseURL}${tagInfo.previewImageSVG}`
            );
          }
        }

        if (tagInfo.previewImageJPG) {
          const imagePreviewElement =
            document.getElementById("imagePreviewJpg");
          if (imagePreviewElement) {
            imagePreviewElement.style.display = "block";
            imagePreviewElement.setAttribute(
              "src",
              `${baseURL}${tagInfo.previewImageJPG}`
            );
          }
        }

        if (tagInfo?.frameworkInfo) {
          setReactInfo(tagInfo.frameworkInfo[0]);
          setVueInfo(tagInfo.frameworkInfo[1]);
        }
      }
    } catch (error) {
      //console.log('error happened , error = ', error);
    }
  };

  const detectImageChange = async (event) => {
    // console.log('inside image on change method ');
    event.preventDefault();

    //console.log('id = ', event.currentTarget.id);
    const targetId = event.currentTarget.id;

    if (event.currentTarget.files.length > 0) {
      const base64File = await toBase64(event.currentTarget.files[0], targetId);
      let imgPreviewId = "";
      if (targetId == "previewJpg") {
        /** PREVIEW IMAGE OF JPG FORMAT */
        // console.log('inside if condition');
        setPreviewImgJpg(base64File);
        imgPreviewId = "imagePreviewJpg";
      } else {
        /** PREVIEW IMAGE OF SVG FORMAT  */
        setPreviewImgSvg(base64File);
        imgPreviewId = "imagePreviewSvg";
      }
      /** AFTER LOADING THE FILE WITH FILE READER , SET THE SOURCE ATTRIBUTE OF IMAGE PREVIEW */
      const imagePreviewElement = document.getElementById(imgPreviewId);
      if (imagePreviewElement) {
        imagePreviewElement.style.display = "block";
        imagePreviewElement.setAttribute("src", base64File);
      }
    }
  };

  /** NEED TO CONVERT THIS METHOD AS A SERVICE TO THIS APPLICATION */
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        /** RESOLVE THE PROMISE TO RETURN THE BASE64 CONVERTED VALUE */
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });

  const tagCreateUpdate = async (event) => {
    event.preventDefault();

    /** CHECK FOR EMPTY TAG NAME ENTRY */
    if (tagName && parentCategory && tagTitle && tagDescription) {
      let tag = {
        title: tagName,
        category: parentCategory,
        name: tagTitle,
        order: tagOrder,
        label: label,
        isFeatured: isFeatured,
        description: tagDescription,
        previewImageJPG: previewImgJpg,
        previewImageSVG: previewImgSvg,
        isAvailableAllAccess: isAvailableAllAccess,
        frameworkInfo: [reactInfo, vueInfo],
      }; 

      if (location.state) {
        /** CONCATENATE WITH PRODUCT ID FROM LOCATION HOOK FETCHED FROM REACT ROUTER DOM */
        tag = { ...tag, _id: location.state.tagId };
      }
      try {
        /** SEND SAVE PRODUCT REQUEST TO API SERVER WHETHER IT'S AN UPDATE OR CREATE */
        const createdTag = await saveTag(tag);

        // console.log('created tag = ', createdTag)

        /** REDIRECT USER TO PRODUCT LIST PAGE IS SAVING OF PRODUCT IS SUCCESSFULL */
        if (createdTag && createdTag._id) {
          history.push("/tagList");
        } else {
          if (createdTag.error && createdTag.error.includes("duplicate key")) {
            alert(
              `Error happended , Tag Name already exist, try with a different name for tag`
            );
          } else {
            alert(
              `Error happended , ${createdTag.error ? createdTag.error : ""}`
            );
          }
        }
      } catch (error) {
        // console.log('error happened on saving tag')
      }
    } else {
      alert("One or more input fields remains empty");
    }
  };

  return (
    <>
      <div className="container">
        <div className="accountSettings">
          {/* <div className="title">
                        <h2>Account Settings</h2>
                    </div> */}

          <div className="accountSettings__box">
            <div className="subtitle">
              <h4>Create Tag</h4>
            </div>
            <form className="accountSettings__box__info">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="email">Tag Name(URL slug)</label>
                    <input
                      type="text"
                      name="text"
                      placeholder="Enter name of the tag"
                      value={tagName}
                      onChange={(e) => setTagName(e.currentTarget.value)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="email">Select Parent Category</label>
                    <select
                      value={parentCategory}
                      onChange={(e) => setParentCategory(e.currentTarget.value)}
                    >
                      <option value="">Select Category</option>
                      {selectOption.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="single-input">
                    <label htmlFor="isFeatured">Is Featured</label>
                    <select
                      value={isFeatured}
                      onChange={(e) => setIsFeatured(e.currentTarget.value)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      name="text"
                      id="title"
                      placeholder="Enter title for the tag"
                      value={tagTitle}
                      onChange={(e) => setTagTitle(e.currentTarget.value)}
                    />
                  </div>
                  <div className="mt-5 single-input">
                    <p>Label</p>
                    <div className="tag-lebel">
                      <label for="new">New</label>
                      <input
                        type="radio"
                        id="new"
                        name="name"
                        value="New"
                        checked={label === "New"}
                        onChange={(e) => setLabel(e.currentTarget.value)}
                      />
                    </div>
                    <div className="tag-lebel">
                      <label for="featured">Featured</label>
                      <input
                        type="radio"
                        id="featured"
                        name="name"
                        value="Featured"
                        checked={label === "Featured"}
                        onChange={(e) => setLabel(e.currentTarget.value)}
                      />
                    </div>
                    <div className="tag-lebel">
                      <label for="hot">Hot</label>
                      <input
                        type="radio"
                        id="hot"
                        name="name"
                        value="Hot"
                        checked={label === "Hot"}
                        onChange={(e) => setLabel(e.currentTarget.value)}
                      />
                    </div>
                    <div className="tag-lebel">
                      <label for="none">None</label>
                      <input
                        type="radio"
                        id="none"
                        name="name"
                        value="none"
                        checked={label === ""}
                        onChange={(e) => setLabel("")}
                      />
                    </div>

                    {/* <input
                      type="text"
                      name="label"
                      id="label"
                      placeholder="Enter New, Upcoming, etc."
                      value={label}
                      onChange={(e) => setLabel(e.currentTarget.value)}
                    /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-input">
                    <ReactQuill
                      modules={modules}
                      theme="snow"
                      value={tagDescription}
                      onChange={(content, delta, source, editor) =>
                        setTagDescription(editor.getHTML())
                      }
                    />
                    {/* <label htmlFor="email">Description</label>
                    <textarea
                      type="text"
                      rows="4"
                      cols="50"
                      name="text"
                      placeholder="Enter title for the tag"
                      value={tagDescription}
                      onChange={(e) => setTagDescription(e.currentTarget.value)}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="email">Show in GrayGrids All Access</label>
                    <select
                      className="w-25 p-1"
                      value={isAvailableAllAccess}
                      onChange={(e) => setIsAvailableAllAccess(e.target.value)}
                    >
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="single-input">
                    <label htmlFor="email">Order</label>
                    <input
                      type="number"
                      name="text"
                      placeholder="Enter order of the category"
                      value={tagOrder}
                      onChange={(e) => setTagOrder(e.currentTarget.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="preview-image">
                    <h3>Preview Image (jpg)</h3>
                    <div className="file-upload">
                      <input
                        id="previewJpg"
                        type="file"
                        name="previewImage"
                        onChange={detectImageChange}
                      />
                      <label htmlFor="previewImage">
                        <i className="lni lni-upload" style={{ zIndex: 2 }}></i>
                        <span style={{ zIndex: 2 }}>
                          {" "}
                          Upload Preview Image (format : .jpg)
                        </span>

                        <img
                          id="imagePreviewJpg"
                          src=""
                          alt="preview Image"
                          width="348"
                          height="198"
                          style={{ position: "inherit", display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="preview-image">
                    <h3>Preview Image (svg)</h3>
                    <div className="file-upload">
                      <input
                        id="previewSvg"
                        type="file"
                        name="previewImage"
                        onChange={detectImageChange}
                      />
                      <label htmlFor="previewImage">
                        <i className="lni lni-upload" style={{ zIndex: 2 }}></i>
                        <span style={{ zIndex: 2 }}>
                          {" "}
                          Upload Preview Image (format : .svg)
                        </span>

                        <img
                          id="imagePreviewSvg"
                          src=""
                          alt="preview Image"
                          width="348"
                          height="198"
                          style={{ position: "inherit", display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="mt-5"> Framework specific metadata</h4>

                <div className="flex">
                  {tabInfo.map((tab) => (
                    <button
                      key={tab.id}
                      onClick={(e) => {
                        e.preventDefault();

                        setActiveTab(tab.id);
                      }}
                      className={`btn ${
                        tab.id === activeTab ? "btn-active" : "btn-default"
                      }`}
                    >
                      {tab.title}
                    </button>
                  ))}
                </div>

                {activeTab === 1 ? (
                  <FrameworkInfo
                    framework={"React"}
                    info={reactInfo}
                    setInfo={setReactInfo}
                  />
                ) : (
                  <FrameworkInfo
                    framework={"Vue"}
                    info={vueInfo}
                    setInfo={setVueInfo}
                  />
                )}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="button-wrapper">
                    <button
                      id="createTag"
                      className="main-btn"
                      onClick={tagCreateUpdate}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTag;
