import {
    addIcon,
    createIconsCategory,
    deleteIconsCategory,
    getAllSecureCategories,
    getCategoryInfo,
    removeIcon,
    updateIcon,
    updateIconCategory,
} from "../values";
import { deleteRequest, getRequest, postRequest, setHeader, setToken } from "./apiService";

export const createIconCategory = async (product) => {
    try {
        setToken(localStorage.getItem('userToken'));
        setHeader('Content-Type', 'multipart/form-data')
        const responseData = await postRequest(createIconsCategory, product)
        return responseData?.data;
    }
    catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }
}

export const getAllIconCategories = async () => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(getAllSecureCategories)
        return responseData?.data
    }
    catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }
}

export const getIconCategoryInfoBySlug = async (slug) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(`${getCategoryInfo}/${slug}`)
        return responseData?.data
    }
    catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }

}

export const updateTheIconCategory = async (info) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(updateIconCategory, info)
        return responseData?.data
    } catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }
}

export const deleteIconCategory = async (slug) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await deleteRequest(`${deleteIconsCategory}/${slug}`)
        return responseData?.data
    }
    catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }
}

export const createIcon = async (data) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(addIcon, data)
        return responseData?.data
    } catch (error) {
        return error
    }
}

export const updateIconInfo = async (data) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(updateIcon, data)
        return responseData?.data
    } catch (error) {
        return error
    }
}

export const deleteIcon = async (category, IconName) => {
    try {
        setToken(localStorage.getItem('userToken'));
        const responseData = await deleteRequest(`${removeIcon}/${category}/${IconName}`)
        return responseData?.data
    }
    catch (error) {
        console.log('inside create category adapter , error = ', error)
        return error
    }
}