import "../ProductList/ProductList.scss";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {getAllUsers} from '../../adapters/loginAdapter';

const UserList = () => {

    const [users,setUsers] = useState([]);

    useEffect(()=>{
        getUserData();
    },[]);
    
    const getUserData = async () => {
        try {
            const fetchedUsers = await getAllUsers();
            if(fetchedUsers){
                setUsers(fetchedUsers);
            }
        }
        catch (error) {
           // console.log('error happened , error = ', error);
        }
    }

    return (
        <>
            <div className="container">
                <div className="accessList accessList--wrapper">
                    {/** HEADER SECTION  */}
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>  {/* className="title" */}
                        <h2>User List</h2>
                        
                    </div>

                    {/** TAG LIST TABLE */}
                    <div className="accessList__table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="w-140"> User Email </span>
                                    </th>
                                    <th>
                                        <span className="w-140">Date-Created</span>
                                    </th>
                                    <th>
                                        <span>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => (
                                    <UserListItem user={user} key={user._id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList;

const UserListItem = ({ user }) => {
    return (
        <tr>
            <td>
                {user.email}
            </td>
            <td>
                {user.createdAt.split('T')[0]}
            </td>
            <td>
                <p>
                    <Link to={{
                        pathname: "/updateUser",
                        state: { userId: user._id ? user._id : "" }
                    }} className="main-btn">
                        Edit
                    </Link>
                </p>
            </td>
        </tr>
    )
}