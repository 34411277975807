import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { addTemplateTag } from "../../adapters/templates";
import Spinner from "../Spinner";

const AddTagTitlePage = () => {
  const history = useHistory();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    const detail = {
      ...data,
      [e.target.name]: e.target.value,
    };
    setData(detail);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await addTemplateTag(data);
    setLoading(false);
    if (response?.status === 200) {
      history.push("/templatesTags");
    } else {
      setError("There is a server error");
    }
  };

  return (
    <div className="container">
      <h2 className="card-title mt-3 mb-3">Add Template tag</h2>
      <div>
        <form onSubmit={handleSave}>
          {error?.length > 0 && <p className="text-danger">{error}</p>}
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-5">Name:</h5>
            <input
              className="w-75  p-2  ms-5 w-25"
              type="text"
              name="name"
              placeholder="Name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-5">Slug: </h5>
            <input
              className="w-75  p-2  ms-5 w-25"
              type="text"
              name="slug"
              placeholder="Slug"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-5">OG Title:</h5>
            <input
              className="w-75  p-2  ms-5 w-25"
              type="text"
              name="ogTitle"
              placeholder="Open Graph Title"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 d-flex align-items-center">
            <h5 className="me-4">OG Image Link:</h5>
            <input
              className="w-75 p-2 ms-2 w-25"
              type="text"
              name="ogImageLink"
              placeholder="Open Graph Image Link"
              onChange={handleChange}
            />
          </div>
          <div className="mt-4 d-flex">
            <h5>OG Description: </h5>
            <textarea
              className="w-75  p-2  ms-4 w-25"
              type="text"
              name="ogDescription"
              placeholder="Open Graph Description"
              rows={6}
              onChange={handleChange}
            />
          </div>
          <div className="text-center mt-5 mb-5">
            <button
              disabled={loading ? true : false}
              type="submit"
              className="btn btn-primary ps-3 pe-3"
            >
              Save {loading && <Spinner />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTagTitlePage;
