import {setToken,postRequest,getRequest} from './apiService';
import {categoryList, getCategory, createCategory, updateCategory, deleteCategory,categoryProducts} from '../values'

/** SERVICE METHOD FOR GETTING ALL CATEGORY INFORMATION FROM API SERVER  */
export const getAllCategories = async () =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest(categoryList);

        //console.log('inside product adapter , response data = ', responseData)   

        if(responseData.status === 200){
            return responseData.data.data;
        }

         
    }
    catch(error){
        return [];
    }
}

/** SERVICE METHOD FOR GETTING DETAIL INFORMATION ABOUT A SPECIFIC CATEGORY BY ID  */
export const getCategoryDetail = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( getCategory , {_id:id});

        if(responseData.status === 200){
            return responseData.data.data;
        }

        //console.log('inside get product , response data = ', responseData)    
    }
    catch(error){
        return {};
    }
}

/** SERVICE METHOD FOR SAVING CATEGORY TO API SERVER */
export const saveCategory = async(category)=>{
    try{

        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest(category._id ? updateCategory : createCategory , category) 

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return responseData.data;
        }

      //  console.log('inside update product , response data = ', responseData)
    }
    catch(error){
        return {};
    }
}

/** SERVICE METHOD TO DELETE A CATEGORY */
export const removeCategory = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await postRequest( deleteCategory , {_id : id})

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return responseData.data;
        }

       // console.log('inside update product , response data = ', responseData)   
    }
    catch(error){
        return {};
    }
}

export const getCategorizedTags = async (id) =>{
    try{
        setToken(localStorage.getItem('userToken'));
        const responseData = await getRequest( categoryProducts + '?categoryId='+id);

       // console.log('respose data =',responseData.data);

        if(responseData.status === 200){
            return responseData.data.data;
        }
        else{
            return [];
        }
    }
    catch(error){
        return {};
    }
}

