import axios from "axios";
import { baseURL } from "../config";

/** CREATE A SINGLETON INSTANCE OF AXIOS */
const instance = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 10000,
  headers: {
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin":"*"
  },
});

/** SET THE AUTHENTICATION TOKEN TO AXIOS */
export const setToken = (token) => {
  instance.defaults.headers.common["Authorization"] = token || "";
};

export const setHeader = (key, value) => {
  instance.defaults.headers[key] = value || "";
};


/** GET REQUEST FOR FETCHING DATA FROM SERVER */
export const getRequest = async (url) => {
  try {
    const response = await instance.get(url);
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

/** POST REQUEST FOR INSERTING NEW DATA TO SERVER */
export const postRequest = async (url, postObject) => {
  try {
    const response = await instance.post(url, postObject);
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};


/** DELETE REQUEST FOR FETCHING DATA FROM SERVER */
export const deleteRequest = async (url) => {
  try {
    const response = await instance.delete(url);
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

/** API RESPONSE HANDLER */
const responseHandler = (responseObj) => {
  if (responseObj.data) {
    let { data, status } = responseObj;
    return { data, status };
  }
  return null;
};

/** API ERROR RESPONSE HANDLER */
const errorHandler = (responseObj) => {
  //console.log('inside api error handler');
  if (responseObj.response) {
    // console.log(responseObj.response);
    // console.log(responseObj.response.data)
    // setAlertMessage(responseObj.response.data.message || 'Something went wrong!!!', 'error')

    // if (responseObj.response.data.message === 'Invalid session please login again.') {
    //     localStorage.removeItem('credential')
    //     window.location.reload()
    // }

    
    return {
      data: responseObj.response.data,
      status: responseObj.response.status,
    };
  }
};

export default instance;
