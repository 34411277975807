import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import {getAllCategories, removeCategory} from '../../adapters/categoryAdapter'

const CategoryList = () => {

    const [categories, setCategories] = useState([])

    useEffect(() => {
        getCategoryData();
    }, [])

    const getCategoryData = async () =>{
        try {
            const fetchedCategories = await getAllCategories();
            setCategories(fetchedCategories);
        }
        catch (error) {
           // console.log('error happened , error = ', error);
        }
    }

    const deleteCategory = async (id) => {
        try {
            const deletedCategory = await removeCategory(id);
            
            if (deletedCategory && deletedCategory._id) {
                getCategoryData();
            }
            else{
                alert(`Error happened ${deletedCategory.error ? deletedCategory.error : ""}`)
            }
        }
        catch (error) {
           // console.log('error happened , error = ', error);
        }
    }

    return (
        <>
            <div className="container">
                <div className="accessList accessList--wrapper">
                    {/** HEADER SECTION  */}
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>  {/* className="title" */}
                        <h2>List of categories</h2>
                        <Link to="/addCategory" className="main-btn">
                            Create New 
                        </Link>
                    </div>

                    

                    {/** TAG LIST TABLE */}
                    <div className="accessList__table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <span className="w-140"> Category Name </span>
                                    </th>
                                    <th>
                                        <span className="w-140">Date-Created</span>
                                    </th>
                                    <th>
                                        <span>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {categories.map((category) => (
                                    <CategoryListItem category={category} deleteAction={deleteCategory} key={category._id} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryList;

const CategoryListItem = ({ category, deleteAction }) => {
    return (
        <tr>
            <td>
                {category.title}
            </td>
            <td>
                {category.createdAt.split('T')[0]}
            </td>
            <td>
                <p>
                    <Link to={{
                        pathname: "/addCategory",
                        state: { categoryId: category._id ? category._id : "" }
                    }} className="main-btn">
                        View
                    </Link>

                    <button className="main-btn" style={{ backgroundColor: "red", marginLeft: "4%" }} onClick={(e) => deleteAction(category._id)}>
                        Delete
                    </button>
                </p>
            </td>
        </tr>
    )
}