import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteIconCategory,
  getAllIconCategories,
} from "../../adapters/iconAdapter";

const Index = () => {
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [totalIcon, setTotalIcon] = useState(0);

  const loadAllCategories = async () => {
    const result = await getAllIconCategories();
    if (!result?.isError) {
      setCategories(result?.categories);
      const totalIcon = result?.categories?.reduce(
        (acc, obj) => acc + obj?.totalIcon,
        0
      );
      setTotalIcon(totalIcon || 0);
    }
    return;
  };

  useEffect(() => {
    loadAllCategories();
  }, []);

  return (
    <section className={`bg-gray mt-3 pb-5 h-full`}>
      <div className="container p-5 align-items-center d-flex justify-content-between box-border">
        <div className="align-items-center d-flex justify-content-between">
          <h4 className="m-0" style={{ fontFamily: "inter" }}>
            Total Category: {categories?.length}
          </h4>
          <h4 style={{ fontFamily: "inter" }} className="m-0 ms-5">
            Total Icon: {totalIcon}
          </h4>
        </div>
        <div className="align-items-center d-flex justify-content-between">
          <input
            type="search"
            placeholder="Search Category by name or slug"
            defaultValue={search}
            style={{ width: "300px" }}
            onInput={(e) => setSearch(e.target.value)}
            className="form-control"
          ></input>
          <Link
            to="/icons/add-category"
            className="d-flex me-5 align-items-center justify-content-around ms-5 bg-primary text-white p-2 rounded hover:bg-opacity-90"
          >
            <span className=" me-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmsns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_150_4325)">
                  <path
                    d="M15.0008 7.45005H8.57578V1.00005C8.57578 0.700049 8.32578 0.425049 8.00078 0.425049C7.70078 0.425049 7.42578 0.675049 7.42578 1.00005V7.45005H1.00078C0.700781 7.45005 0.425781 7.70005 0.425781 8.02505C0.425781 8.32505 0.675781 8.60005 1.00078 8.60005H7.45078V15C7.45078 15.3 7.70078 15.575 8.02578 15.575C8.32578 15.575 8.60078 15.325 8.60078 15V8.57505H15.0008C15.3008 8.57505 15.5758 8.32505 15.5758 8.00005C15.5758 7.70005 15.3008 7.45005 15.0008 7.45005Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_150_4325">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>{" "}
            Add Category
          </Link>
          <Link
            to="/icons/add-icon"
            className="d-flex align-items-center justify-content-around bg-primary text-white p-2 rounded hover:bg-opacity-90"
          >
            <span className="me-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmsns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_150_4325)">
                  <path
                    d="M15.0008 7.45005H8.57578V1.00005C8.57578 0.700049 8.32578 0.425049 8.00078 0.425049C7.70078 0.425049 7.42578 0.675049 7.42578 1.00005V7.45005H1.00078C0.700781 7.45005 0.425781 7.70005 0.425781 8.02505C0.425781 8.32505 0.675781 8.60005 1.00078 8.60005H7.45078V15C7.45078 15.3 7.70078 15.575 8.02578 15.575C8.32578 15.575 8.60078 15.325 8.60078 15V8.57505H15.0008C15.3008 8.57505 15.5758 8.32505 15.5758 8.00005C15.5758 7.70005 15.3008 7.45005 15.0008 7.45005Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_150_4325">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>{" "}
            Add Icon
          </Link>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center m-5">
        <div className="w-75 bg-white overflow-hidden px-4 py-2">
          <table className="table w-100">
            <thead>
              <tr className="rounded-t-[3px] border-b border-stroke ">
                <th className=" py-3  text-left  text-base font-medium text-black">
                  Category Name
                </th>
                <th className="py-3  text-left  text-base font-medium text-black">
                  Slug
                </th>
                <th className="py-3  text-left  text-base font-medium text-black">
                  Total Icons
                </th>

                <th className="py-3  text-center  text-base font-medium text-black">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {categories &&
                categories
                  ?.filter(
                    (value) =>
                      value?.name
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase()) ||
                      value?.slug
                        ?.toLowerCase()
                        ?.includes(search?.toLowerCase())
                  )
                  ?.map((category, index) => (
                    <tr
                      key={index}
                      className="border-b border-stroke last-of-type:border-b-0"
                    >
                      <td className="py-4 text-base text-black">
                        {category.name}
                      </td>
                      <td className="py-4 text-base text-left text-black">
                        {category?.slug}
                      </td>
                      <td className="py-4 text-base text-left text-black">
                        {category?.totalIcon}
                      </td>

                      <td className="py-4 text-base text-black ">
                        <span className="d-flex justify-content-center">
                          <Link
                            to={`/icons/category/${encodeURIComponent(
                              category?.slug
                            )}`}
                            className="btn btn-primary"
                          >
                            <span className="me-2 pt-1">
                              <svg
                                fill="#ffffff"
                                width="16"
                                height="16"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 64 64"
                              >
                                <path d="M61.2,13c-3.2-3.4-6.6-6.8-10-10.1c-0.7-0.7-1.5-1.1-2.4-1.1c-0.9,0-1.8,0.3-2.4,1L8.7,40.2c-0.6,0.6-1,1.3-1.3,2L1.9,59 c-0.3,0.8-0.1,1.6,0.3,2.2c0.5,0.6,1.2,1,2.1,1h0.4l17.1-5.7c0.8-0.3,1.5-0.7,2-1.3l37.5-37.4c0.6-0.6,1-1.5,1-2.4 S61.9,13.7,61.2,13z M20.6,52.1c-0.1,0.1-0.2,0.1-0.3,0.2L7.4,56.6l4.3-12.9c0-0.1,0.1-0.2,0.2-0.3L39.4,16l8.7,8.7L20.6,52.1z M51.2,21.5l-8.7-8.7l6.1-6.1c2.9,2.8,5.8,5.8,8.6,8.7L51.2,21.5z" />
                              </svg>
                            </span>
                            Edit
                          </Link>

                          <RemoveButton
                            loadCategory={loadAllCategories}
                            categorySlug={category?.slug}
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
              {categories?.length === 0 && (
                <tr>
                  <td
                    className="p-5 text-base text-black items-center"
                    colSpan={4}
                  >
                    There are no Category to display
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Index;

const RemoveButton = (props) => {
  const { loadCategory, categorySlug } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const removeCategory = async (categorySlug) => {
    setIsDeleting(true);
    const res = await deleteIconCategory(categorySlug);
    setIsDeleting(false);
    if (!res?.isError) {
      loadCategory();
    }
  };
  return (
    <button
      onClick={() => removeCategory(categorySlug)}
      disabled={isDeleting ? true : false}
      style={{ cursor: isDeleting ? "not-allowed" : "pointer" }}
      className="btn btn-danger ms-5"
    >
      <span className="me-1">
        <svg
          width="14"
          height="14"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.2258 2.20005H10.3008V1.77505C10.3008 1.02505 9.70078 0.425049 8.95078 0.425049H7.02578C6.27578 0.425049 5.67578 1.02505 5.67578 1.77505V2.20005H3.75078C3.02578 2.20005 2.42578 2.80005 2.42578 3.52505V4.27505C2.42578 4.82505 2.75078 5.27505 3.22578 5.47505L3.62578 14.125C3.67578 14.95 4.32578 15.575 5.15078 15.575H10.7758C11.6008 15.575 12.2758 14.925 12.3008 14.125L12.7508 5.45005C13.2258 5.25005 13.5508 4.77505 13.5508 4.25005V3.50005C13.5508 2.80005 12.9508 2.20005 12.2258 2.20005ZM6.82578 1.77505C6.82578 1.65005 6.92578 1.55005 7.05078 1.55005H8.97578C9.10078 1.55005 9.20078 1.65005 9.20078 1.77505V2.20005H6.85078V1.77505H6.82578ZM3.57578 3.52505C3.57578 3.42505 3.65078 3.32505 3.77578 3.32505H12.2258C12.3258 3.32505 12.4258 3.40005 12.4258 3.52505V4.27505C12.4258 4.37505 12.3508 4.47505 12.2258 4.47505H3.77578C3.67578 4.47505 3.57578 4.40005 3.57578 4.27505V3.52505ZM10.8008 14.45H5.20078C4.97578 14.45 4.80078 14.275 4.80078 14.075L4.40078 5.60005H11.6258L11.2258 14.075C11.2008 14.275 11.0258 14.45 10.8008 14.45Z"
            fill="currentColor"
          />
          <path
            d="M8.00078 8.1001C7.70078 8.1001 7.42578 8.3501 7.42578 8.6751V11.8501C7.42578 12.1501 7.67578 12.4251 8.00078 12.4251C8.30078 12.4251 8.57578 12.1751 8.57578 11.8501V8.6751C8.57578 8.3501 8.30078 8.1001 8.00078 8.1001Z"
            fill="currentColor"
          />
          <path
            d="M9.99994 8.60004C9.67494 8.57504 9.42494 8.80004 9.39994 9.12504L9.24994 11.325C9.22494 11.625 9.44994 11.9 9.77494 11.925C9.79994 11.925 9.79994 11.925 9.82494 11.925C10.1249 11.925 10.3749 11.7 10.3749 11.4L10.5249 9.20004C10.5249 8.87504 10.2999 8.62504 9.99994 8.60004Z"
            fill="currentColor"
          />
          <path
            d="M5.97594 8.60004C5.67595 8.62504 5.42595 8.90004 5.45095 9.20004L5.62595 11.4C5.65095 11.7 5.90095 11.925 6.17595 11.925C6.20095 11.925 6.20094 11.925 6.22594 11.925C6.52594 11.9 6.77594 11.625 6.75094 11.325L6.57594 9.12504C6.57594 8.80004 6.30094 8.57504 5.97594 8.60004Z"
            fill="currentColor"
          />
        </svg>
      </span>
      {isDeleting ? "Removing..." : "Remove"}
    </button>
  );
};
