import axios from "axios";
import React, { useState } from "react";
import { flashServerCache } from "../../adapters/tagAdapter";

const frontendUrl = "https://tailgrids.com";

const OperationPage = () => {
  const [reIndexing, setReIndexing] = useState(false);
  const [flushingCache, setFlushingCache] = useState(false);
  const [flushingIndex, setFlushingIndex] = useState(false);

  const handleFlush = async () => {
    try {
      setFlushingCache(true);
      const data = await flashServerCache();
      setFlushingCache(false);
      setTimeout(() => {
        alert(data?.message || "Cache flushed successfully");
      }, 1000);
    } catch (error) {
      setFlushingCache(false);
      alert(error.message || "Something went wrong");
    }
  };

  const handleReIndex = async () => {
    try {
      setReIndexing(true);
      const result = await axios.post(
        `${frontendUrl}/api/search/index-data?secret=tailgrids_2024`
      );
      setReIndexing(false);
      if (result?.status === 200) {
        setTimeout(() => {
          alert(result?.data?.message || "Indexing done successfully");
        }, 1000);
      }
    } catch (error) {
      setReIndexing(false);
      alert(error.message || "Something went wrong");
    }
    return;
  };

  const handleFlushIndex = async () => {
    try {
      setFlushingIndex(true);
      const result = await axios.post(
        `${frontendUrl}/api/search/clear-data?secret=tailgrids_2024`
      );
      setFlushingIndex(false);
      if (result?.status === 200) {
        setTimeout(() => {
          alert(result?.data?.message || "Index flushed successfully");
        }, 1000);
      }
    } catch (error) {
      setFlushingIndex(false);
      alert(error.message || "Something went wrong");
    }
    return;
  };

  return (
    <div className="container">
      <h2>Operations Page</h2>
      <div className="border rounded p-4 mt-4">
        <h3 className="mb-3">Flush the api server cache or redis cache</h3>
        <button
          className="btn btn-primary p-2 pointer rounded me-3 text-light"
          style={{ cursor: flushingCache ? "not-allowed" : "pointer" }}
          disabled={flushingCache ? true : false}
          onClick={() => handleFlush()}
        >
          {flushingCache ? (
            <>
              Flushing{" "}
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="currentColor"
                x="128"
                y="128"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="currentColor">
                  <path
                    fill="currentColor"
                    d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                  >
                    <animateTransform
                      attributeName="transform"
                      dur="0.75s"
                      repeatCount="indefinite"
                      type="rotate"
                      values="0 12 12;360 12 12"
                    />
                  </path>
                </g>
              </svg>
            </>
          ) : (
            "Flush Cache"
          )}
        </button>
      </div>
      <div className="border rounded p-4 mt-4">
        <h3 className="mb-3">Search Index Operations</h3>
        <div className="d-flex">
          <div className="w-50 p-4 border rounded">
            <p>
              Re-index the components, templates and blogs in algolia server.
              Static data will not re-indexed.
            </p>
            <button
              onClick={() => handleReIndex()}
              disabled={reIndexing ? true : false}
              style={{ cursor: reIndexing ? "not-allowed" : "pointer" }}
              className="btn mt-4 btn-primary p-2 pointer rounded me-3 text-light"
            >
              {reIndexing ? (
                <>
                  Indexing{" "}
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    x="128"
                    y="128"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="currentColor">
                      <path
                        fill="currentColor"
                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        />
                      </path>
                    </g>
                  </svg>
                </>
              ) : (
                "Re index"
              )}
            </button>
          </div>
          <div className="w-50 p-4 border rounded ms-4">
            <p>Flush or delete all the data from the algolia server.</p>
            <button
              onClick={() => handleFlushIndex()}
              disabled={flushingIndex ? true : false}
              style={{ cursor: flushingIndex ? "not-allowed" : "pointer" }}
              className="btn mt-4 btn-primary p-2 pointer rounded me-3 text-light"
            >
              {flushingIndex ? (
                <>
                  Flushing{" "}
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    x="128"
                    y="128"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="currentColor">
                      <path
                        fill="currentColor"
                        d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      >
                        <animateTransform
                          attributeName="transform"
                          dur="0.75s"
                          repeatCount="indefinite"
                          type="rotate"
                          values="0 12 12;360 12 12"
                        />
                      </path>
                    </g>
                  </svg>
                </>
              ) : (
                "Flush index"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperationPage;
