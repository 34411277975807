import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import AddCategory from "./components/AddCategory/AddCategory";
import AddPlan from "./components/AddPlan/AddPlan";
import AddSnippet from "./components/AddSnippet/AddSnippet";
import AddTag from "./components/AddTag/AddTag";
import CategoryList from "./components/CategoryList/CategoryList";
import Login from "./components/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import PlanList from "./components/PlanList/PlanList";
import ProductList from "./components/ProductList/ProductList";
import TagList from "./components/TagList/TagList";
import TemplatesTags from "./components/TemplateTags";
import Templates from "./components/Templates";
import AddTemplate from "./components/Templates/AddTemplate";
import UpdateUser from "./components/UpdateUser/UpdateUser";
import UserList from "./components/UserList/UserList";
import Home from "./pages/Home/Home";

import IconIndex from "./components/Icons";
import AddIcon from "./components/Icons/AddIcon";
import AddIconCategory from "./components/Icons/Category/AddCategory";
import Detail from "./components/Icons/Category/Detail";
import UpdateIcon from "./components/Icons/UpdateIcon";
import OperationPage from "./components/Operations";
import AddTagTitlePage from "./components/TemplateTags/AddTemplateTag";
import UpdateTemplateTag from "./components/TemplateTags/UpdateTemplateTag";
import TemplateDetail from "./components/Templates/TemplateDetail";
import UpdateTemplate from "./components/Templates/UpdateTemplate";
import { LoginContextProvider } from "./contexts/LoginContext";

const App = () => {
  return (
    <LoginContextProvider>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/productList">
            <ProductList />
          </Route>
          <Route path="/addTag">
            <AddTag />
          </Route>
          <Route path="/tagList">
            <TagList />
          </Route>
          <Route path="/addCategory">
            <AddCategory />
          </Route>
          <Route path="/categoryList">
            <CategoryList />
          </Route>
          <Route path="/accountSettings">
            <AccountSettings />
          </Route>
          <Route path="/addProduct">
            <AddSnippet />
          </Route>
          <Route path="/addPlan">
            <AddPlan />
          </Route>
          <Route path="/planList">
            <PlanList />
          </Route>
          <Route path="/userList">
            <UserList />
          </Route>
          <Route path="/updateUser">
            <UpdateUser />
          </Route>
          <Route path="/addTemplates">
            <AddTemplate />
          </Route>
          <Route path="/updateTemplates/:id">
            <UpdateTemplate />
          </Route>
          <Route path="/templates">
            <Templates />
          </Route>
          <Route path="/templatesTags">
            <TemplatesTags />
          </Route>
          <Route path="/addTemplateTag">
            <AddTagTitlePage />
          </Route>
          <Route path="/updateTemplatesTags/:id">
            <UpdateTemplateTag />
          </Route>
          <Route path="/singleTemplate/:id">
            <TemplateDetail />
          </Route>
          <Route path="/icons/add-category">
            <AddIconCategory />
          </Route>
          <Route exact path="/icons/">
            <IconIndex />
          </Route>
          <Route path="/icons/category/:slug">
            <Detail />
          </Route>
          <Route path="/icons/add-icon">
            <AddIcon />
          </Route>
          <Route path="/icons/update-icon/:category">
            <UpdateIcon />
          </Route>
          <Route path="/operations">
            <OperationPage />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </LoginContextProvider>
  );
};

export default App;
