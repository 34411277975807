import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteTemplateTag,
  getAllTemplateTags,
} from "../../adapters/templates";

const Templates = () => {
  const [allTags, setAllTags] = useState([]);

  const getAllTags = async () => {
    const tags = await getAllTemplateTags();
    if (tags?.status === 200) {
      setAllTags(tags?.data?.data);
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure to delete this Tag");
    if (confirm) {
      const deleteResponse = await deleteTemplateTag(id);
      if (deleteResponse?.status === 200) {
        getAllTags();
      }
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div className="container mt-5">
      <Link to="/addTemplateTag" className="main-btn cursor-pointer">
        Add Template Tag
      </Link>
      <div style={{ flexWrap: "wrap" }} className="d-flex mt-5">
        {allTags?.length > 0 &&
          allTags?.map((tag) => (
            <div
              key={tag?._id}
              className="card bg-light m-3"
              style={{ minWidth: "18rem", maxWidth: "18rem" }}
            >
              <img
                style={{ height: "150px", objectFit: "cover" }}
                className="card-img-top"
                src={tag?.ogImageLink}
                alt="Og-img"
              />
              <div className="card-header">{tag?.name}</div>
              <div className="card-body">
                <h5 className="">Slug: {tag?.slug}</h5>
                <h5 className="">Title: {tag?.ogTitle}</h5>
                <p className="card-text">
                  {tag?.ogDescription &&
                    `${tag?.ogDescription?.slice(0, 150)}...`}
                </p>
              </div>
              <div className="d-flex ps-4 pe-4 pb-2">
                <button
                  onClick={() => handleDelete(tag?._id)}
                  title="delete"
                  className="btn btn-danger ps-3 pe-3 cursor-pointer"
                >
                  Delete
                </button>
                <Link
                  className="ms-auto cursor-pointer"
                  to={`updateTemplatesTags/${tag?._id}`}
                >
                  <button className="btn btn-warning ps-4 pe-4">Edit</button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Templates;
