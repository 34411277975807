import {
  createTag,
  deleteTag,
  flushCache,
  getTag,
  reCachedAllSecureTagDetails,
  tagList,
  tagWiseProduct,
  updateTag,
} from "../values";
import { getRequest, postRequest, setToken } from "./apiService";

/** SERVICE METHOD FOR GETTING ALL TAG INFORMATION FROM API SERVER  */
export const getAllTags = async () => {
  try {
    setToken(localStorage.getItem("userToken"));
    const responseData = await getRequest(tagList);

    //console.log('inside product adapter , response data = ', responseData)

    if (responseData.status === 200) {
      return responseData.data.data;
    }
  } catch (error) {
    return [];
  }
};

/** SERVICE METHOD FOR GETTING DETAIL INFORMATION ABOUT A SPECIFIC PRODUCT BY ID  */
export const getTagDetail = async (id) => {
  try {
    setToken(localStorage.getItem("userToken"));
    const responseData = await postRequest(getTag, { _id: id });

    if (responseData.status === 200) {
      return responseData.data.data;
    }

    // console.log('inside get product , response data = ', responseData)
  } catch (error) {
    return {};
  }
};

/** SERVICE METHOD FOR SAVING TAG INFORMATION TO API SERVER */
export const saveTag = async (tag) => {
  try {
    setToken(localStorage.getItem("userToken"));
    const responseData = await postRequest(
      tag._id ? updateTag : createTag,
      tag
    );

    //console.log('inside update product , response data = ', responseData)

    if (responseData.status === 200) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }
  } catch (error) {
    console.log("inside error block save tag , ", error);
    return {};
  }
};

export const removeTag = async (id) => {
  try {
    setToken(localStorage.getItem("userToken"));
    const responseData = await postRequest(deleteTag, { _id: id });

    if (responseData.status === 200) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }

    // console.log('inside update product , response data = ', responseData)
  } catch (error) {
    return {};
  }
};

export const getProductsByTag = async (id) => {
  try {
    setToken(localStorage.getItem("userToken"));
    // console.log("");

    const responseData = await getRequest(tagWiseProduct + "?tagid=" + id);

    //console.log('response data =' , responseData.data);

    if (responseData.status === 200) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }
  } catch (error) { }
};

/** SERVICE METHOD FOR GETTING DETAIL INFORMATION ABOUT A SPECIFIC PRODUCT BY ID  */
export const reCachedAllSecureTagDetail = async () => {
  try {
    setToken(localStorage.getItem("userToken"));
    // console.log("");

    const responseData = await getRequest(reCachedAllSecureTagDetails);

    //console.log('response data =' , responseData.data);

    if (responseData.status === 200) {
      return responseData.data.data;
    } else {
      return responseData.data;
    }
  } catch (error) { }
};

/** Flush the server cache  */
export const flashServerCache = async () => {
  try {
    setToken(localStorage.getItem("userToken"));
    // console.log("");

    const responseData = await getRequest(flushCache);

    //console.log('response data =' , responseData.data);

    if (responseData.status === 200) {
      return responseData.data;
    } else {
      return responseData.data;
    }
  } catch (error) { }
};
