
import { useState, createContext, useContext } from 'react'

const LoginContext = createContext();

export function LoginContextProvider({ children }) {
    const [isLoggedin, setIsLoggedin] = useState(localStorage.getItem('isUserLoggedIn') || false);

    return (
        <LoginContext.Provider value={{
            loggedIn: isLoggedin,
            changeLoginStatus: async (status) => {
               // console.log('inside login statsu change , isLoggedin = ', isLoggedin);
                setIsLoggedin(status);
            }
        }}>
            {children}
        </LoginContext.Provider>
    );
}

export function useLoginContext() {
    return useContext(LoginContext);
}